import tw, { styled, css, theme } from "twin.macro";

import type { ITypographyStyledCommon } from "@/types/common";

const labelStyles = {
  small: {
    sm: tw`text-[0.7rem] tracking-[0.00625rem]`,
    md: tw`text-[0.75rem] tracking-[0.0075rem]`,
    lg: tw`text-[0.875rem] tracking-[0.00875rem]`,
  },
  medium: {
    sm: tw`text-[0.75rem] tracking-[0.0075rem]`,
    md: tw`text-[0.875rem] tracking-[0.00875rem]`,
    lg: tw`text-[1rem] tracking-[0.01rem]`,
  },
  large: {
    sm: tw`text-[0.875rem]`,
    md: tw`text-[1rem]`,
    lg: tw`text-[1.125rem]`,
  },
};

export const StyledWrapperLabel = styled.label<ITypographyStyledCommon>`
  ${tw`font-primaryV2 text-neutral-10 transition-all duration-300 ease-in-out font-semibold leading-[normal]
  `}

  & > * {
    ${tw`[font-family: inherit] font-[inherit] [color: inherit]`};
  }

  ${({ $sizeVariant, $size }) => {
    if ($size) {
      return labelStyles[$size][$sizeVariant];
    }

    return css`
      ${labelStyles.small[$sizeVariant]}

      @media (min-width: ${theme`screens.medium`}) {
        ${labelStyles.medium[$sizeVariant]}
      }

      @media (min-width: ${theme`screens.large`}) {
        ${labelStyles.large[$sizeVariant]}
      }
    `;
  }}

  ${({ $color }) => {
    if ($color) {
      return css`
        color: ${$color};
      `;
    }
  }};

  ${({ $disabled }) => {
    if ($disabled) {
      return tw`text-neutral-60`;
    }
  }};
`;
