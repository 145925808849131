import {
  StyledIconError,
  StyledIconInfo,
  StyledIconWarning,
  StyledNotificationItem,
  StyledNotificationWrapper,
  StyledText,
} from "./styles";

interface NotificationProps {
  messages: string | string[];
  type: "info" | "warning" | "error";
  hFull?: boolean;
}

interface renderMessagesProps {
  messages: NotificationProps["messages"];
  type: NotificationProps["type"];
}
function renderIcon(type: NotificationProps["type"]) {
  switch (type) {
    case "info":
      return <StyledIconInfo />;
    case "warning":
      return <StyledIconWarning />;
    case "error":
      return <StyledIconError />;
    default:
      return null;
  }
}

function renderMessages({ messages, type }: renderMessagesProps) {
  if (messages instanceof Array) {
    return messages.map((message, index) => (
      <StyledNotificationItem key={index} type={type}>
        {renderIcon(type)}
        <StyledText key={index} sizeVariant="sm" type={type}>
          {message}
        </StyledText>
      </StyledNotificationItem>
    ));
  }
  if (typeof messages === "string") {
    return (
      <StyledNotificationItem type={type}>
        {renderIcon(type)}
        <StyledText sizeVariant="sm" type={type}>
          {messages}
        </StyledText>
      </StyledNotificationItem>
    );
  }
}
const Notification = ({ messages, type, hFull }: NotificationProps) => {
  return (
    <StyledNotificationWrapper type={type}>{renderMessages({ messages, type })}</StyledNotificationWrapper>
  );
};
export default Notification;
