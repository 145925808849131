import { type RouteObject } from "react-router-dom";

const privateRoutes: RouteObject[] = [
  {
    path: "orders",
    lazy: async () => {
      const { Orders } = await import("@/screens/Orders");
      return {
        element: <Orders />,
      };
    },
  },
  {
    path: "orders/:orderId",
    lazy: async () => {
      const { Order } = await import("@/screens/Order");
      return {
        element: <Order />,
      };
    },
  },
  {
    path: "profile",
    lazy: async () => {
      const { Profile } = await import("@/screens/Profile/Profile");
      return {
        element: <Profile />,
      };
    },
  },
  {
    path: "profile/plus",
    lazy: async () => {
      const { ProfileMembership } = await import("@/screens/Profile/ProfileMembership");
      return {
        element: <ProfileMembership />,
      };
    },
  },
  {
    path: "referral",
    lazy: async () => {
      const { Referral } = await import("@/screens/Referral");
      return {
        element: <Referral />,
      };
    },
  },
  {
    path: "payment-method/validate",
    lazy: async () => {
      const { PaymentMethodValidate } = await import("@/screens/PaymentMethodValidate");
      return {
        element: <PaymentMethodValidate />,
      };
    },
  },
  {
    path: "referred",
    lazy: async () => {
      const { Referred } = await import("@/screens/Referred");
      return {
        element: <Referred />,
      };
    },
  },
];

export default privateRoutes;
