import { useCallback, useEffect, useId, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "twin.macro";
import { Swiper } from "swiper";
import { A11y, Autoplay, EffectFade } from "swiper/modules";
import NotificationAlert from "@atoms/NotificationAlert";
import NotificationPlus from "@molecules/NotificationPlus";
import IconBlogFlag from "@iconsV2/Header/blog-flag.svg?react";
import FlashLogo from "@iconsV2/Header/flash-logo.svg?react";
import IconReferralLogo from "@iconsV2/Header/referral-logo.svg?react";
import { useFlash, useMembership } from "@/hooks";
import useSesionContext from "@hooks/useSesionContext";
import { useMediaQuery } from "@hooks/useMediaQuery";
import { IS_ACTIVE_PLUS } from "@/recourses/constants";
import "swiper/css/effect-fade";

const sessionKey = "notificationsHeader";
const autoPlayTime = "10000"; // 10 seconds

const NotificationsHeader = () => {
  const location = useLocation();
  const [openNotifications, setOpenNotifications] = useState(false);

  const id = useId();
  const isMedium = useMediaQuery("( min-width: {medium} )");
  const swiperRef = useRef<Swiper | null>(null);
  const swiperClassName = `swiper-notifications-${id.replaceAll(":", "")}`;

  const isCheckout = location.pathname.includes("/checkout");
  const { isAthenticated } = useSesionContext();

  const [hasMembership] = useMembership();
  const canShowPlusBar = !hasMembership && IS_ACTIVE_PLUS;

  const [isFlash] = useFlash();

  const isAuthenticatedUser = isAthenticated();

  const handleClose = useCallback(() => {
    setOpenNotifications(false);

    const expirationTime = new Date().getTime() + 30 * 60000; // Now + 30 minutes;
    sessionStorage.setItem(sessionKey, String(expirationTime));
  }, []);

  useEffect(() => {
    // Validate if show notifications header
    const expirationTime = sessionStorage.getItem(sessionKey);
    const now = new Date().getTime();

    if (!expirationTime || now >= +expirationTime) {
      sessionStorage.removeItem(sessionKey);
      setOpenNotifications(true);
    } else {
      setOpenNotifications(false);
    }
  }, []);

  useEffect(() => {
    // Swiper
    if (openNotifications) {
      swiperRef.current = new Swiper(`.${swiperClassName}`, {
        modules: [Autoplay, A11y, EffectFade],
        slidesPerView: 1,
        loop: true,
        autoplay: true,
        effect: "fade",
        speed: 1000,
        spaceBetween: 30,
        followFinger: false,
        fadeEffect: {
          crossFade: true,
        },
        allowTouchMove: false,
      });

      return () => {
        swiperRef.current?.destroy();
      };
    }
  }, [openNotifications, isAuthenticatedUser, isFlash, canShowPlusBar]);

  if (isCheckout || !openNotifications) return null;

  return (
    <div className={`swiper ${swiperClassName}`} tw="w-full">
      <div className="swiper-wrapper" tw="w-full">
        {canShowPlusBar && (
          <div className="swiper-slide" data-swiper-autoplay={autoPlayTime}>
            <NotificationPlus handleClose={handleClose} />
          </div>
        )}

        {isFlash && (
          <div className="swiper-slide" data-swiper-autoplay={autoPlayTime}>
            <Link to="/flash">
              <NotificationAlert
                startIcon={<FlashLogo />}
                title={!isMedium ? "Entregas en 15 min" : "Entregas en 15 min. pagando con tarjeta*"}
                handleClose={handleClose}
              />
            </Link>
          </div>
        )}

        {isAuthenticatedUser && (
          <div className="swiper-slide" data-swiper-autoplay={autoPlayTime}>
            <Link to="/referral">
              <NotificationAlert
                startIcon={<IconReferralLogo />}
                title={!isMedium ? "Invita y gana $ 10.000" : "Invita tus amigos y gana $ 10.000"}
                handleClose={handleClose}
              />
            </Link>
          </div>
        )}

        <div className="swiper-slide" data-swiper-autoplay={autoPlayTime}>
          <Link
            to="https://blog.fithub.com.co"
            title="Blog de Fithub"
            target="_blank"
            rel="noopener noreferrer"
          >
            <NotificationAlert
              startIcon={<IconBlogFlag />}
              title={
                !isMedium
                  ? "Evoluciona tus hábitos con nuestro blog"
                  : "Aprende a evolucionar tus hábitos con nuestro blog"
              }
              handleClose={handleClose}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotificationsHeader;
