import useAppDispatch from "@/hooks/useAppDispatch";
import useAppSelector from "@/hooks/useAppSelector";
import useModalAuth from "@/hooks/useModalAuth";
import { GOOGLE_MAP_LIBRARIES, GOOGLE_MAPS_API_KEY } from "@/recourses/constants";
import { genericsAction } from "@/store/generics";
import { useJsApiLoader } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import useAddressModal from "@/hooks/useAddressModal";

export default function useRootLayout() {
  const dispatch = useAppDispatch();
  const { handleCloseModalAuth, isOpen: isAuthModalOpen } = useModalAuth();
  const storeId = useAppSelector((state) => state.cart.storeId);
  const showLocationModal = useAppSelector((state) => state.popUpsReminder.showLocationModal);
  const [oldStoreId, setOldStoreId] = useState<number | undefined>(storeId);

  const { isOpen: isAddressModalOpen, toggleAddressModal: setIsAddressModalOpen } = useAddressModal();

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: GOOGLE_MAP_LIBRARIES,
    language: "es",
    region: "CO",
  });

  const handleCloseModalInformativeChangeLocation = () => {
    setOldStoreId(storeId);
  };

  useEffect(() => {
    dispatch(genericsAction.setIsLoadedScriptGoogleMaps({ isLoaded, loadError }));
  }, [isLoaded]);

  useEffect(() => {
    setOldStoreId(storeId);
  }, [storeId !== undefined]);

  return {
    handleCloseModalAuth,
    isAuthModalOpen,
    oldStoreId,
    storeId,
    isOpenModalInformativeChangeLocation:
      storeId !== undefined && storeId !== oldStoreId && !showLocationModal,
    isAddressModalOpen,
    handleCloseModalInformativeChangeLocation,
    setIsAddressModalOpen,
  };
}
