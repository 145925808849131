import tw, { css, styled } from "twin.macro";
import type { TSize } from "@/types/common";

const isBgDarkest = (backgroundColor: string) => {
  const hexaColor = backgroundColor.substring(1);
  const rgb = parseInt(hexaColor, 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;

  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  // luma range is 0 - 255
  if (luma < 150) {
    // luma range is 0 - 127 (dark)
    return true;
  } else {
    // luma range is 128 - 255 (light)
    return false;
  }
};

export const StyledWrapperNew = styled.div<{ $size?: TSize }>`
  ${tw`relative py-xxxs pr-xxxs pl-xs flex items-center justify-center rounded-round rounded-r-none  w-fit bg-tertiary-50 mr-1.5
    medium:(py-xxs pr-xxs pl-xs)
    large:(py-xxs pr-xxs pl-s)
  `}

  & > svg {
    ${tw`absolute h-full -right-2`}

    & path {
      ${tw`fill-tertiary-40`}
    }
  }
`;

export const StyledWrapperDiscount = styled(StyledWrapperNew)`
  ${tw`bg-secondary-50`}

  & > svg {
    & path {
      ${tw`fill-secondary-40`}
    }
  }
`;

export const StyledWrapperIsPromoted = styled.div`
  ${tw`py-xxxs px-xxs rounded-round bg-primaryV2-100 bg-opacity-80
    medium:(py-xxs px-xs)
  `}
`;

export const StyledWrapperCustomLabel = styled.div<{ $bg?: string; $label: string }>`
  ${tw`py-xxxs px-xxs rounded-round rounded-l-none flex items-center gap-xxxs shadow-elevation-1 w-fit
    medium:(py-xs px-s)
    large:(py-s px-sm)
  `}

  ${({ $bg, $label }) => {
    if ($bg && $label) {
      return css`
        background-color: ${$bg};
        color: ${isBgDarkest($bg) ? tw`text-primaryV2-100` : tw`text-primaryV2-0`};
      `;
    }

    if ($label) {
      return tw`bg-complement-99 text-tertiary-50`;
    }
  }}


  & > svg {
    ${tw`w-[0.625rem] h-[0.625rem]`}

    & path {
      fill: currentColor;
    }
  }
`;

export const StyledWrapperBestSeller = styled.div`
  ${tw`py-xxxs px-xxs rounded-round rounded-l-none flex items-center gap-xxxs shadow-elevation-1 [background: linear-gradient(270deg, #46B96B 0%, #397F4F 100%)] w-fit
    medium:(py-xs px-s)
    large:(py-s px-sm)
  `}
`;
