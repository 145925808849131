import React, { type ChangeEvent, useState } from "react";
import tw, { css } from "twin.macro";
import IconSearch from "@/components/Icons/V2/Interrface/magnifier-search-01.svg?react";
import Paragraph from "@/components/Atoms/Paragraph";
import {
  StyledPhoneInputWrapper,
  StyledLined,
  StyledPhoneInput,
  StyledIconChevronDown,
  StyledSupportText,
} from "./styles";
import Label from "@/components/Atoms/Label";
import { type CountryITem, countryList } from "@/utils/CountriesConfig/countryList";
import Popover, { PopoverContent, PopoverTrigger } from "@/components/Atoms/Popover";
import Button from "@/components/Atoms/Button";
import PopoverButton from "@/components/Atoms/PopoverButton";
import TextField from "@/components/Atoms/TextField";
import FlagEmoji from "@/components/Atoms/FlagEmoji";
import { type TSize } from "@/types/common";

const sanitizePhoneNumber = (code: string, phoneNumber: string) => {
  return `${code}${phoneNumber.replace(/\s/g, "")}`;
};

const applyMask = (value: string) => {
  const cleanValue = value.replace(/\D/g, "");
  const maskedValue = cleanValue.replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2 $3").trim();
  return maskedValue;
};
const initValue = countryList.filter((item: CountryITem) => item.iso === "CO")[0];

export type Status = "error" | "success" | "default";
export interface PhoneInputProps {
  onChange: (value: string) => void;
  label: string;
  placeholder?: string;
  textHelper?: string;
  status?: Status;
  size?: TSize;
  withReferenceWidth?: boolean;
  defaultPhone?: string;
  defaultAreaCode?: CountryITem;
  disabled?: boolean;
}

export const PhoneInput = ({
  onChange,
  label,
  placeholder,
  textHelper,
  status = "default",
  size,
  withReferenceWidth,
  defaultPhone = "",
  defaultAreaCode = initValue,
  disabled,
}: PhoneInputProps) => {
  const [openSelect, setOpenSelect] = useState(false);
  const [selectedAreaCode, setSelectedAreaCode] = useState(defaultAreaCode);
  const [countryListFilter, setCountryListFilter] = useState(countryList);
  const [haveResults, setHaveResults] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState(defaultPhone);
  const [searchValue, setSearchValue] = useState("");

  const toggleOpenSelect = (open: boolean) => {
    setOpenSelect(open);
  };

  function handleSelectCountry(country: CountryITem) {
    setSelectedAreaCode(country);
    setOpenSelect(false);
    onChange(sanitizePhoneNumber(country.code, phoneNumber));
  }

  function handleAreaCodeChange(e: ChangeEvent<HTMLInputElement>) {
    const value = e.target.value.trim().toLowerCase();

    setSearchValue(value);

    const searchResults = countryList.filter((item) => {
      let newValue;
      if (value.includes("+")) {
        newValue = value.replace("+", "");
      } else {
        newValue = value;
      }

      if (typeof newValue === "string") {
        return Object.values(item).some((val) => String(val).toLowerCase().includes(newValue));
      }

      return false;
    });

    if (searchResults.length > 0) {
      setCountryListFilter(searchResults);
      setHaveResults(true);
    } else {
      setHaveResults(false);
    }
  }

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const maskedValue = applyMask(value);
    setPhoneNumber(maskedValue);
    onChange(sanitizePhoneNumber(selectedAreaCode.code, maskedValue));
  };

  return (
    <Popover
      open={openSelect}
      onOpenChange={toggleOpenSelect}
      placement="bottom-start"
      shift={false}
      flip={false}
      withReferenceWidth={withReferenceWidth ?? false}
    >
      <div tw="relative">
        {label && (
          <Label sizeVariant="md" size={size}>
            {label}
          </Label>
        )}

        <PopoverTrigger tw="w-full">
          <StyledPhoneInputWrapper $status={status} $size={size}>
            <Button
              type="button"
              sizeVariant="lg"
              color="neutral"
              variant="text"
              size={size}
              onClick={() => toggleOpenSelect(!openSelect)}
              startIcon={<FlagEmoji ISO={selectedAreaCode.iso} />}
              endIcon={<StyledIconChevronDown $open={openSelect} />}
              disabled={disabled}
            >
              + {selectedAreaCode.code}
            </Button>
            <StyledLined />
            <StyledPhoneInput
              type="text"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              maxLength={15}
              placeholder={placeholder}
              $status={status}
              $size={size}
              disabled={disabled}
            />
          </StyledPhoneInputWrapper>
        </PopoverTrigger>
        <PopoverContent
          classes={{
            root: css`
              ${tw`z-[52] h-80 overflow-y-auto flex flex-col gap-s`}
            `,
          }}
        >
          <TextField
            size={size}
            value={searchValue}
            placeholder="Buscar"
            endIcon={<IconSearch fontSize={32} tw="cursor-pointer" />}
            onChange={handleAreaCodeChange}
          />
          {haveResults &&
            countryListFilter.map((item, index) => (
              <PopoverButton
                startIcon={<FlagEmoji ISO={item.iso} />}
                key={index}
                label={`${item.country} +${item.code}`}
                onClick={() => handleSelectCountry(item)}
              />
            ))}
          {!haveResults && (
            <Paragraph sizeVariant="md" tw="text-center p-s" size={size}>
              No se encontraron resultados
            </Paragraph>
          )}
        </PopoverContent>
        {textHelper && (
          <StyledSupportText sizeVariant="sm" $status={status}>
            {textHelper}
          </StyledSupportText>
        )}
      </div>
    </Popover>
  );
};
