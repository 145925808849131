import { useQuery } from "@tanstack/react-query";

import { getCreditsAvailable } from "@/services/credits";
import useSessionContext from "./useSesionContext";
import { CreditsEnum } from "@/recourses/queryKeys";

interface IUseCredits {
  creditsAvailable?: boolean;
}

export default function useCredits(props?: IUseCredits) {
  const { userToken } = useSessionContext();

  const creditsAvailable = useQuery({
    queryKey: [CreditsEnum.creditsAvailable],
    queryFn: getCreditsAvailable,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    staleTime: 6000 * 60 * 5,
    enabled: Boolean(userToken) && props?.creditsAvailable,
  });

  return {
    creditsAvailable: creditsAvailable.data,
  };
}
