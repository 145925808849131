import SignUpUserInfo, { type SignUpUserInfoData } from "@/components/Molecules/SignUpUserInfo";
import { useState } from "react";
import SignUpUserDetails, { type SignUpUserDetailsData } from "@/components/Molecules/SignUpUserDetails";
import useSessionContext from "@/hooks/useSesionContext";
import Analytics from "@/utils/Analytics";
import { createUser } from "@/services/auth";
import { useMutation } from "@tanstack/react-query";
import { type AuthStep } from "@/components/Navbar/AuthModal/types";

interface SignUpProps {
  onClose: () => void;
  isMobile?: boolean;
  updateCurrentUser: (data: Omit<UserAttr, "token">) => void;
  setStep: React.Dispatch<React.SetStateAction<AuthStep>>;
  tokenUserRegistration: string;
}
export interface SignUpData {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  // hear_about_us_source: string;
  identification: string;
  identification_type: string;
}

const SignUp = ({ onClose, updateCurrentUser, setStep, tokenUserRegistration }: SignUpProps) => {
  const { setUserToken } = useSessionContext();

  const [stepSingUp, setStepSignUp] = useState<0 | 1>(0);
  const [date, setData] = useState({
    email: "",
    password: "",
    first_name: "",
    last_name: "",
    // hear_about_us_source: "",
    identification: "",
    identification_type: "",
  });
  const [fetchError, setFetchError] = useState<string[] | string>([]);

  const signUp = useMutation({
    mutationFn: createUser,
    onSuccess: (data) => {
      // Identify users in all analytics tools
      Analytics.Identify({
        id: String(data.id),
        user: {
          email: data.email,
          firstName: `${data.first_name}`,
          lastName: `${data.last_name}`,
          phone: data.telephone,
          createdAt: data.date_joined,
        },
      });
      Analytics.CompleteRegistration({
        id: data.id,
        user: {
          email: data.email,
          firstName: `${data.first_name}`,
          lastName: `${data.last_name}`,
          phone: data.telephone,
          createdAt: data.date_joined,
        },
      });

      updateCurrentUser(data);
      setUserToken(data.token);

      onClose();
    },
    onError: (error: any) => {
      if (error) {
        setFetchError(
          (error.response.data.email as string[] | string) ||
            (error.response.data.detail as string[] | string) ||
            (error.response.data?.user as string[] | string)
        );
        setTimeout(() => {
          setFetchError([]);
        }, 5000);
      }
    },
  });

  const handleSubmitUserInfo = (data: SignUpUserInfoData) => {
    setStepSignUp(1);
    setData((prev) => ({ ...prev, email: data.email, password: data.password }));
  };

  const handleSubmitUserDetails = async (data: SignUpUserDetailsData) => {
    setData((prev) => ({
      ...prev,
      first_name: data.first_name,
      last_name: data.last_name,
      identification: data.identification,
      identification_type: data.identification_type,
    }));

    if (data) {
      await signUp.mutateAsync({
        email: date.email,
        password: date.password,
        firstName: data.first_name,
        lastName: data.last_name,
        identification: data.identification,
        identificationType: data.identification_type,
        token: tokenUserRegistration,
      });
    }
  };

  function backStepAuth() {
    setStep("login");
  }

  function backStep() {
    setStepSignUp(0);
  }
  return (
    <>
      {stepSingUp === 0 && (
        <SignUpUserInfo onClose={onClose} onSubmit={handleSubmitUserInfo} backStep={backStepAuth} />
      )}
      {stepSingUp === 1 && (
        <SignUpUserDetails
          onClose={onClose}
          onSubmit={handleSubmitUserDetails}
          fetchingError={fetchError}
          backStep={backStep}
        />
      )}
    </>
  );
};

export default SignUp;
