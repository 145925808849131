import React, { createContext, useEffect } from "react";
import { useLocalStorage } from "../hooks";
import { useQuery } from "@tanstack/react-query";
import { verifyTokenJWT } from "@/services/auth";
import { userAction } from "@/store/user";
import useAppDispatch from "@/hooks/useAppDispatch";

export interface SessionContextType {
  userToken: string | null;
  setUserToken: (value: string | null) => void;
  isAthenticated: () => boolean;
}

const sessionContext = createContext<SessionContextType>(null!);

interface SessionProviderProps {
  children: React.ReactNode;
}

const SessionProvider = ({ children }: SessionProviderProps): React.ReactElement => {
  const [userToken, setUserToken] = useLocalStorage<string | null>("userToken", null);
  const dispatch = useAppDispatch();

  const { data, isError } = useQuery({
    queryKey: ["verifyTokenJWT"],
    queryFn: verifyTokenJWT,
    enabled: !!userToken,
    refetchIntervalInBackground: true,
    refetchInterval: 1000 * 60 * 60,
  });

  const isAthenticated = () => {
    return !!window.localStorage.getItem("userToken");
  };

  useEffect(() => {
    if (isError) {
      setUserToken(null);
      dispatch(userAction.emptyUser());
    }
  }, [isError]);

  useEffect(() => {
    if (data) {
      dispatch(
        userAction.setUser({
          id: data.user.id,
          firstName: data.user.first_name,
          lastName: data.user.last_name,
          email: data.user.email,
          phoneNumber: data.user.telephone,
          creditAvaliable: {
            gross: data.user.credit_available.gross,
            grossLocalized: data.user.credit_available.gross_localized,
            net: data.user.credit_available.net,
            netLocalized: data.user.credit_available.net_localized,
            currency: data.user.credit_available.currency,
          },
          referralCreditAvailable: {
            gross: data.user.referral_credit_available.gross,
            grossLocalized: data.user.referral_credit_available.gross_localized,
            net: data.user.referral_credit_available.net,
            netLocalized: data.user.referral_credit_available.net_localized,
            currency: data.user.referral_credit_available.currency,
          },
          birthday: data.user.dob,
          gender: data.user.gender,
          hearAboutUs: data.user.hear_about_us_source,
          publicId: data.user.public_id,
          identification: data.user.identification,
          identificationType: data.user.identification_type,
          membership_savings: data.user.membership_savings,
          membership: data.user.membership,
        })
      );
    }
  }, [data]);

  return (
    <sessionContext.Provider value={{ userToken, setUserToken, isAthenticated }}>
      {children}
    </sessionContext.Provider>
  );
};

export { sessionContext, SessionProvider };
