import { type FC, forwardRef, type PropsWithRef, type PropsWithChildren, type CSSProperties } from "react";
import { type RuleSet } from "styled-components";
import { type TwStyle } from "twin.macro";
import { StyledWrapperCard, StyledZigZag } from "./styles";

export type ICard = PropsWithChildren<{
  classes?: {
    root?: RuleSet<object> | TwStyle;
  };
  withShadow?: boolean;
  withZigzag?: boolean;
  borderRadius?: CSSProperties["borderRadius"];
  [key: string]: any;
}>;

const Card: FC<PropsWithRef<ICard>> = forwardRef<HTMLDivElement, ICard>(
  ({ classes, children, withShadow = true, withZigzag = false, borderRadius, ...props }, ref) => {
    const CardComponent = (
      <StyledWrapperCard
        ref={ref}
        $classes={classes?.root}
        $withShadow={withShadow}
        $withZigzag={withZigzag}
        $borderRadius={borderRadius}
        {...props}
      >
        {children}
      </StyledWrapperCard>
    );

    if (withZigzag) {
      return (
        <div tw="relative">
          {CardComponent}
          <StyledZigZag />
        </div>
      );
    }

    return CardComponent;
  }
);

export default Card;
