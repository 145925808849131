import IconWarning from "@iconsV2/Warnings/information-circle.svg?react";

import Caption from "../Caption";
import Paragraph from "../Paragraph";
import {
  StyledWrapperBestSeller,
  StyledWrapperCustomLabel,
  StyledWrapperDiscount,
  StyledWrapperIsPromoted,
  StyledWrapperNew,
} from "./styles";
import Tooltip, { TooltipContent, TooltipTrigger } from "@/components/Atoms/Tooltip";

interface IProductCardTagDiscountProps {
  type: "discount";
  amount: string;
}

interface IProductCardTagCustomLabelProps {
  type: "customLabel";
  label: string;
  labelBackground?: string;
}

interface IProductCardTagProps {
  type: "new" | "promoted" | "bestSeller";
}

type TProductCardTagProps = (
  | IProductCardTagDiscountProps
  | IProductCardTagCustomLabelProps
  | IProductCardTagProps
) &
  Record<string, any>;

const ProductCardTag = ({ type, ...props }: TProductCardTagProps) => {
  if (!type) {
    throw new TypeError("Type is required");
  }

  if (type === "new") {
    return (
      <StyledWrapperNew {...props}>
        <Paragraph sizeVariant="sm" color="white" fontWeight="700">
          Nuevo
        </Paragraph>

        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="22" viewBox="0 0 8 22" fill="none">
          <path d="M0 0H8L2.28571 11L8 22H0V11V0Z" fill="#483483" />
        </svg>
      </StyledWrapperNew>
    );
  }
  if (type === "discount") {
    return (
      <StyledWrapperDiscount>
        <Paragraph sizeVariant="sm" color="white" fontWeight="700">
          {(props as IProductCardTagDiscountProps).amount}%
        </Paragraph>

        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="22" viewBox="0 0 8 22" fill="none">
          <path d="M0 0H8L2.28571 11L8 22H0V11V0Z" fill="#483483" />
        </svg>
      </StyledWrapperDiscount>
    );
  }

  if (type === "promoted") {
    return (
      <StyledWrapperIsPromoted {...props}>
        <Caption>Promocionado</Caption>
      </StyledWrapperIsPromoted>
    );
  }
  if (type === "bestSeller") {
    return (
      <StyledWrapperBestSeller {...props}>
        <Paragraph sizeVariant="sm" fontWeight="700" color="white">
          Más vendido
        </Paragraph>
      </StyledWrapperBestSeller>
    );
  }
  if (type === "customLabel") {
    const { labelBackground, label, ...rest } = props as IProductCardTagCustomLabelProps;

    return (
      <Tooltip placement="bottom-end">
        <TooltipTrigger>
          <StyledWrapperCustomLabel {...rest} $bg={labelBackground} $label={label}>
            <Paragraph sizeVariant="sm" fontWeight="700" color="inherit">
              {label}
            </Paragraph>

            <IconWarning />
          </StyledWrapperCustomLabel>
        </TooltipTrigger>
        <TooltipContent tw="max-w-[14.313rem] z-1">
          <Paragraph sizeVariant="sm">
            Son productos en excelente condición pero con fecha cercanas de vencimiento.
          </Paragraph>
        </TooltipContent>
      </Tooltip>
    );
  }
};

export default ProductCardTag;
