import { useEffect, useId, useRef } from "react";
import Swiper from "swiper";
import { theme } from "twin.macro";

import HubCard from "@/components/Atoms/HubCard";
import { type IHub } from "@/types/hubs";
import { StyledWrapperHubs } from "./styles";
import HubLoader from "../HubLoader";
import { A11y } from "swiper/modules";

interface IHubProps {
  hubs: IHub[];
  isLoading: boolean;
}

const HubsSection = ({ isLoading, hubs }: IHubProps): React.ReactElement => {
  const id = useId();
  const swiperRef = useRef<Swiper | null>(null);
  const swiperClassName = `swiper-hubs-container-${id.replaceAll(":", "")}`;

  useEffect(() => {
    if (hubs) {
      swiperRef.current = new Swiper(`.${swiperClassName}`, {
        modules: [A11y],
        grabCursor: true,
        slidesPerView: "auto",
        spaceBetween: 6,
        breakpoints: {
          [theme`screens.large`.replace("px", "")]: {
            spaceBetween: 16,
          },
        },
      });
    }

    return () => {
      swiperRef.current?.destroy();
    };
  }, [JSON.stringify(hubs)]);

  return (
    <div className={`swiper ${swiperClassName}`} tw="w-full">
      <StyledWrapperHubs className="swiper-wrapper">
        {!isLoading ? (
          <>
            {hubs.map((hub) => (
              <HubCard hub={hub} className="swiper-slide" key={hub.pk} />
            ))}
          </>
        ) : (
          <>
            {Array.from({ length: 8 }).map((_, i) => (
              <HubLoader className="swiper-slide" key={i} />
            ))}
          </>
        )}
      </StyledWrapperHubs>
    </div>
  );
};

export default HubsSection;
