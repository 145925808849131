import Modal from "@/components/Atoms/Modal";
import Paragraph from "@/components/Atoms/Paragraph";
import PromoContent from "@/components/Molecules/PromoContent";
import { useLocalStorageWithExpiry } from "@/hooks/useLocalStorageWithExpiry";
import useModalAuth from "@/hooks/useModalAuth";
import useSessionContext from "@/hooks/useSesionContext";
import { REGISTER_PROMOTION } from "@/recourses/constants";
import posthog from "posthog-js";
import { useEffect, useState } from "react";
import tw from "twin.macro";
import { filterCities } from "@/utils/CountriesConfig";
import { getCorrectCityName } from "@/utils/CorrectCityNames";

const IS_PROMO_EXPIRED = (promo: boolean | null) => promo === null || !!promo;

const PROMO_KEY = "promoModal";
const PromoModal = (): React.ReactElement => {
  const { handleOpenModalAuth } = useModalAuth();
  const { isAthenticated } = useSessionContext();
  const userAuth = isAthenticated();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [promoExpiry, setPromoExpiry] = useLocalStorageWithExpiry<boolean | null>("promoModal", "1 day");
  const availableStores = filterCities("CO", true).map((city) => city.code);

  const openModal = () => setIsOpen(true);
  const closeModal = () => {
    setIsOpen(false);
    markPromoAsSeen(); // Mark the promo as seen
  };

  const markPromoAsSeen = () => {
    return sessionStorage.setItem(PROMO_KEY, "true");
  };

  const handleAcceptPromo = () => {
    handleOpenModalAuth({ initialStep: "phoneNumber" });
    closeModal();
    markPromoAsSeen(); // Mark the promo as seen
    setPromoExpiry(false); // Prevent showing the modal again
  };

  useEffect(() => {
    if (isOpen || userAuth) return;

    // read the promo status from the local storage
    const isPromoSeen = sessionStorage.getItem(PROMO_KEY);
    // if the promo has been seen, return
    if (isPromoSeen) return;

    // Check if the "halfUsers" feature is enabled
    const isFeatureEnabled = posthog.isFeatureEnabled("halfUsers");

    // If the feature is enabled, proceed to check promo conditions
    if (isFeatureEnabled) {
      // Determine if the promotional modal should be shown based on promotion status and expiry
      const shouldShowPromo = REGISTER_PROMOTION && IS_PROMO_EXPIRED(promoExpiry);
      // Open the promotional modal if all conditions are met
      if (shouldShowPromo) openModal();
    }
  }, [userAuth]);

  return (
    <>
      <Modal
        showCenter
        isOpen={isOpen}
        handleClose={closeModal}
        classes={{ card: tw`p-0 w-[92%] md:(max-w-[34.5rem]) overflow-visible` }}
        closeOutSise
      >
        <PromoContent
          title="Obtén tu envío gratis"
          promoCode="newfithub"
          description="Regístrate y en tu primera compra, ingresa el código promocional 👇"
          actionTitle="Crear cuenta"
          terms={
            <Paragraph sizeVariant="sm" tw="inline-block">
              Válido para compras superiores a <b>$50.000</b> en{" "}
              {availableStores.map((store, index) => {
                const isLast = index === availableStores.length - 1;
                const secondToLast = index === availableStores.length - 2;
                const comma = !isLast && !secondToLast && ", ";
                const separatorAnd = isLast && " y ";
                return (
                  <span key={index}>
                    {separatorAnd}
                    <span key={store} tw="capitalize">
                      {getCorrectCityName(store)}
                      {comma}
                    </span>
                  </span>
                );
              })}
            </Paragraph>
          }
          onClick={handleAcceptPromo}
          onClose={closeModal}
        />
      </Modal>
    </>
  );
};

export default PromoModal;
