import tw, { css, styled } from "twin.macro";
import LogoFithubPrimary from "@iconsV2/Brand/primary.svg?react";
import { type TSize } from "@/types/common";
import applyResponsiveStyles from "@/utils/applyResponsiveStyles";
import IconChevromDown from "@iconsV2/Arrow/chevron-bottom-normal.svg?react";

export const styles = {
  root: {
    base: tw`w-full flex flex-col top-0 left-0 z-[50] bg-primaryV2-100 pb-xxs transition-[translate] duration-300 ease-in-out gap-s pt-xxs medium:(gap-sm)`,
  },
  wrapper: {
    base: tw`w-full flex items-center justify-between gap-m medium:(gap-sm)`,
  },
  body: {
    base: tw`flex items-end justify-between w-full medium:(gap-m)`,
  },
  logo: {
    base: tw`w-[4.625rem] h-[1.5rem]
      medium:(w-[5.375rem] h-[1.5rem])
      large:(w-[9.0625rem] h-[2rem])
    `,
  },
  iconChevronDown: {
    base: tw`transition-transform duration-300 ease-in-out transform min-[750px]:(w-5 h-5)`,
    size: {
      small: tw`w-[1.125rem] h-[1.125rem]`,
      large: tw`w-6 h-6`,
    },
  },
};

export const StyledRootHeader = styled.header<{
  $size?: TSize;
  $isScrolled: boolean;
}>`
  ${styles.root.base}

  ${({ $isScrolled }) => {
    if ($isScrolled) {
      return tw`shadow-elevation-2 animate-slideDown sticky`;
    }
  }}
`;

export const StyledHeaderHead = styled.div`
  ${styles.wrapper.base}
`;

export const StyledHeaderHeadLeft = styled.div<{ $isScrolled: boolean }>`
  ${tw`flex items-center gap-s large:(gap-xxl)`}

  ${({ $isScrolled }) => {
    if ($isScrolled) {
      return tw`large:(gap-s)`;
    }
  }}
`;
export const StyledHeaderHeadRight = styled.div<{ $isScrolled: boolean }>`
  ${tw`flex items-center justify-end gap-m medium:(gap-m) w-[76%] large:(w-[54%] gap-m)`}

  ${({ $isScrolled }) => {
    if ($isScrolled) {
      return tw`w-auto medium:(w-[80%]) min-[1024px]:(w-[52%])  large:(w-[51%])`;
    }
  }}
`;

export const StyledHeaderBody = styled.div`
  ${styles.body.base}
`;

export const StyledLogoFithubPrimary = styled(LogoFithubPrimary)`
  ${styles.logo.base}
`;

export const StyledContainer = styled.div`
  ${tw`w-full px-xxs xxs:px-s flex flex-col gap-s large:(px-m)`}
`;

export const StyledPlaceholder = styled.div<{
  $height: number;
}>`
  ${({ $height }) => {
    return css`
      height: ${$height}px;
    `;
  }}
`;

export const StyledIconChevronDown = styled(IconChevromDown)<{ $size?: TSize; $open: boolean }>`
  ${styles.iconChevronDown.base}

  ${({ $size }) => applyResponsiveStyles({ stylesBreakpoints: styles.iconChevronDown.size, size: $size })}
  ${({ $open }) => $open && tw`rotate-180`}
  & path {
    ${tw`fill-current`}
  }
`;

export const StyledWrapperPopoverButtons = styled.div`
  ${tw`grid px-m pb-ml gap-x-m gap-y-ml medium:(pb-0 grid-cols-2 px-0 gap-y-s) all-child:(hover:bg-transparent p-0 medium:(p-s hover:bg-neutral-90) large:(p-sm))`}

  .category-list-item-active {
    ${tw`bg-neutral-90`}
  }
`;
