import { genericsAction } from "@/store/generics";
import useAppSelector from "./useAppSelector";
import useAppDispatch from "./useAppDispatch";
import { useNavigate } from "react-router-dom";

export enum CALLBACK_MODAL_ADDRESS {
  NAVIGATE_TO_CHECKOUT = "NAVIGATE_TO_CHECKOUT",
}

const useModalAddress = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state) => state.generics.modalAddress.openModalAddress);
  const callbackIdentifier = useAppSelector((state) => state.generics.modalAddress.callbackIdentifier);

  const toggleAddressModal = (isOpen: boolean, callbackIdentifier?: CALLBACK_MODAL_ADDRESS) => {
    dispatch(genericsAction.setOpenModalAddress({ open: isOpen, callbackIdentifier }));
  };

  const handleCallback = () => {
    if (callbackIdentifier === CALLBACK_MODAL_ADDRESS.NAVIGATE_TO_CHECKOUT) {
      navigate("/checkout/confirm-cart");
    }

    dispatch(genericsAction.setCallbackAddressIdentifier(undefined));
  };
  return {
    isOpen,
    toggleAddressModal,
    handleCallback,
  };
};

export default useModalAddress;
