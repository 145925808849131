import { type ReactNode } from "react";
import { theme } from "twin.macro";
import Paragraph from "@atoms/Paragraph";

import IconAttentionCircle from "@iconsV2/Warnings/information-circle-fill-none.svg?react";
import IconAttentionStop from "@iconsV2/Warnings/attention-stop-fill-none.svg?react";
import IconAttentionTriangle from "@iconsV2/Warnings/attention-triangle-fill-none.svg?react";
import IconCheckedOutline from "@iconsV2/Controllers/radio-checked-outline.svg?react";

import {
  StyledHeaderNotification,
  StyledStartIcon,
  StyledIconClose,
  StyledWrapperNotification,
  StyledBodyNotification,
} from "./styles";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "@/hooks/useMediaQuery";

export type TVariantNotification = "bar" | "default";

export enum ETypeNotification {
  PRIMARY = "primary",
  INFO = "info",
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
}

interface INotificationAlert {
  title: string | ReactNode;
  description?: string | ReactNode;
  type?: ETypeNotification;
  startIcon?: React.ReactElement;
  handleClose?: () => void;
  variant?: TVariantNotification;
}

const TYPE_BANNERS_COLORS = {
  [ETypeNotification.PRIMARY]: {
    title: theme`colors.white`,
    description: theme`colors.white`,
  },
  [ETypeNotification.INFO]: {
    title: theme`colors.secondary.50`,
    description: theme`colors.neutral.10`,
  },
  [ETypeNotification.SUCCESS]: {
    title: theme`colors.tertiary.10`,
    description: theme`colors.neutral.10`,
  },
  [ETypeNotification.ERROR]: {
    title: theme`colors.error.10`,
    description: theme`colors.neutral.10`,
  },
  [ETypeNotification.WARNING]: {
    title: theme`colors.primaryV2.40`,
    description: theme`colors.neutral.10`,
  },
};

const TYPE_BANNERS_ICON = {
  [ETypeNotification.INFO]: <IconAttentionCircle fill={theme`colors.secondary.70`} />,
  [ETypeNotification.SUCCESS]: <IconCheckedOutline fill={theme`colors.tertiary.50`} />,
  [ETypeNotification.ERROR]: <IconAttentionStop fill={theme`colors.error.50`} />,
  [ETypeNotification.WARNING]: <IconAttentionTriangle fill={theme`colors.primaryV2.50`} />,
};

const NotificationAlert = ({
  title,
  description,
  type = ETypeNotification.PRIMARY,
  startIcon,
  handleClose,
  variant = "bar",
}: INotificationAlert) => {
  const currentPath = useLocation().pathname;
  const isProductPage = currentPath.startsWith("/p/");
  const isMedium = useMediaQuery("(min-width: {medium})");
  const hidden = isProductPage && !isMedium;

  return (
    <>
      {!hidden && (
        <StyledWrapperNotification $type={type} $variant={variant}>
          <StyledHeaderNotification $variant={variant}>
            {startIcon && <StyledStartIcon>{startIcon}</StyledStartIcon>}
            {type !== ETypeNotification.PRIMARY && (
              <StyledStartIcon>{TYPE_BANNERS_ICON[type]}</StyledStartIcon>
            )}

            <div tw="flex-1 self-center">
              <Paragraph
                sizeVariant={variant === "bar" ? "md" : "sm"}
                fontWeight={variant === "bar" ? 700 : 400}
                color={TYPE_BANNERS_COLORS[type].title}
              >
                {title}
              </Paragraph>
            </div>

            {handleClose && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleClose();
                }}
              >
                <StyledIconClose $type={type} />
              </button>
            )}
          </StyledHeaderNotification>

          {description && (
            <StyledBodyNotification>
              <Paragraph sizeVariant="md" color={TYPE_BANNERS_COLORS[type].description}>
                {description}
              </Paragraph>
            </StyledBodyNotification>
          )}
        </StyledWrapperNotification>
      )}
    </>
  );
};

export default NotificationAlert;
