import Heading from "@/components/Atoms/Heading";
import Modal from "@/components/Atoms/Modal";
import Paragraph from "@/components/Atoms/Paragraph";
import { getCities } from "@/utils/CountriesConfig";
import { useEffect, useRef, useState } from "react";
import { getAvaliableStores } from "@/services/stores";
import Analytics from "@/utils/Analytics";
import { cartStoresActions } from "@/store/cartStore";
import CityFeedbackForm from "@/components/Molecules/CityFeedbackForm";
import AddressSelectionCard from "@/components/Molecules/AddressSelectionCard";
import useAppSelector from "@/hooks/useAppSelector";
import { COUNTRYCODE } from "@/recourses/constants";
import tw from "twin.macro";
import IconClose from "@iconsV2/Edit/close.svg?react";
import { popupsRemunderAction } from "@/store/PopUpsReminderSlice";
import useAppDispatch from "@/hooks/useAppDispatch";
import useCart from "@/hooks/useCartV2";
import { useQuery } from "@tanstack/react-query";
import { CheckoutEnum } from "@/recourses/queryKeys";
import { getCartAvailableProducts } from "@/services/checkout";
import ModalItemsOutOfStock from "../ModalItemsOutOfStock";

import {
  StyledAddresFormLoader,
  StyledLoaderCheked,
  StyledLoaderLoading,
  StyledSectionSelect,
  StyledWrapperAddresForm,
  StyledWrapperHeader,
} from "./styles";
import useModalAddress from "@/hooks/useAddressModal";

interface ModalAddressFormProps {
  isOpen: boolean;
  toggleAddressModal: (b: boolean) => void;
}

export type FnCloseModal = (
  event?: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLDivElement, MouseEvent>
) => void;

const cities = getCities(COUNTRYCODE);

const ModalAddressForm = ({ isOpen, toggleAddressModal }: ModalAddressFormProps) => {
  const { handleCallback } = useModalAddress();
  const dispatch = useAppDispatch();
  const { totalCountCart, items } = useCart();

  const itemsRef = useRef<Cart[]>([]);
  const selectAddressRef = useRef<CityType | Omit<LocationType, "test" | "isPickUp"> | undefined>();
  const newStoreRef = useRef<AvaliableStoresAttr | undefined>();
  const [showCityFeedbackForm, setShowCityFeedbackForm] = useState<boolean>(false);
  const availableStore = useAppSelector((state) => state.cart.availableStores);
  const listStores = cities?.filter((city: CityType) => city.is_available);
  const location = useAppSelector((state) => state.cart.location);
  const showLocationModal = useAppSelector((state) => state.popUpsReminder.showLocationModal);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [succesChange, setSuccesChange] = useState<boolean>(false);
  const [verifyProductsOutStock, setVerifyProductsOutStock] = useState<boolean>(false);
  const [openModalItemsOutStock, setOpenModalItemsOutStock] = useState<boolean>(false);

  const { data: cartAvailables } = useQuery({
    queryKey: [
      CheckoutEnum.getCartAvailableProducts,
      newStoreRef.current?.stores?.[0]?.id,
      JSON.stringify(itemsRef.current),
    ],
    queryFn: async () => {
      const productsId = itemsRef.current.map((product) => String(product.details.sku)).join(",");
      return await getCartAvailableProducts(Number(newStoreRef.current?.stores?.[0]?.id), productsId);
    },
    enabled:
      items.length > 0 &&
      newStoreRef.current?.stores?.[0]?.id !== availableStore?.id &&
      verifyProductsOutStock,
    staleTime: 2000 * 60 * 5,
  });

  const sendCoverageChangeEvent = (
    lastStoreId: string,
    newStoreId: string,
    lastLocation: Omit<LocationType, "test" | "isPickUp">,
    newLocation: CityType | Omit<LocationType, "test" | "isPickUp">,
    lastCoverageId: number,
    newCoverageId: number
  ): void => {
    Analytics.CoverageChange({
      lastAddress: lastLocation.address,
      lastCoverageZoneId: `${lastCoverageId}`,
      lastLat: `${lastLocation.lat}`,
      lastLng: `${lastLocation.lng}`,
      lastStoreId,
      newAddress: "name" in newLocation ? newLocation.name : newLocation.address,
      newCoverageZoneId: `${newCoverageId}`,
      newLat: `${Number(newLocation.lat as string).toFixed(12)}`,
      newLng: `${Number(newLocation.lng as string).toFixed(12)}`,
      newStoreId,
    });
    Analytics.AddressInCoverage({
      coverageZoneId: `${newCoverageId}`,
      storeId: newStoreId,
    });
  };

  const updateAvailableStores = (availableStores: AvaliableStoresAttr | AvailableStoresSliceType): void => {
    dispatch(
      cartStoresActions.setAvailableStores({
        availableStores,
        validateAt: new Date().getTime(),
      })
    );
  };

  const onDeliveryChangeLocationSuccess = (
    store: AvaliableStoresAttr,
    addressLocation: CityType | Omit<LocationType, "test" | "isPickUp">
  ): void => {
    setIsLoading(true);
    if (String(addressLocation.lat) !== "" && String(addressLocation.lng) !== "") {
      dispatch(
        cartStoresActions.setLocation({
          address: "name" in addressLocation ? addressLocation.name : addressLocation.address,
          lat: Number(addressLocation.lat),
          lng: Number(addressLocation.lng),
          test: "name" in addressLocation,
          city: "city" in addressLocation ? addressLocation.city?.toLowerCase() : store.city.toLowerCase(),
          ...("name" in addressLocation
            ? {}
            : { extra: addressLocation.extra, id: addressLocation.id, alias: addressLocation.alias }),
          isPickUp: false,
        })
      );
    }

    updateAvailableStores(store);

    if (showLocationModal) {
      dispatch(
        popupsRemunderAction.setPopUpsReminder({
          showLocationModal: false,
          showAddressReminder: false,
        })
      );
    }

    dispatch(cartStoresActions.setStoreId(store.stores[0].id));
    setSuccesChange(true);

    setIsLoading(false);
    setSuccesChange(false);
    handleCallback();
  };

  const onSubmit = async (selectAddress: CityType, closeModal: boolean = true): Promise<void> => {
    try {
      if (!selectAddress || selectAddress.city === "") {
        return;
      }

      setIsLoading(true);
      const store = await getAvaliableStores("", Number(selectAddress.lat), Number(selectAddress.lng));

      if (!store) {
        throw new Error("No hay tiendas disponibles");
      }

      if (totalCountCart <= 0) {
        onDeliveryChangeLocationSuccess(store, selectAddress);
        if (closeModal) {
          handleClose();
        }

        !!availableStore?.id &&
          sendCoverageChangeEvent(
            `${availableStore?.stores[0].id}`,
            `${store.stores[0].id}`,
            location,
            selectAddress,
            availableStore?.id,
            store.id
          );
        setIsLoading(false);
        return;
      }

      setVerifyProductsOutStock(true);
      newStoreRef.current = store;
      selectAddressRef.current = selectAddress;
    } catch (error) {
      setIsLoading(false);
      !!selectAddress &&
        Analytics.OutOfCoverage({
          lat: selectAddress.lat,
          lng: selectAddress.lng,
          address: selectAddress.name,
        });
    } finally {
      setShowCityFeedbackForm(false);
    }
  };

  function handleCityFeedbackForm(open: boolean) {
    setShowCityFeedbackForm(open);
  }

  function handleClose() {
    toggleAddressModal(false);

    if (showCityFeedbackForm) {
      setShowCityFeedbackForm(false);
    }
  }

  const handleOnSubmitItemOutStock = () => {
    onDeliveryChangeLocationSuccess(newStoreRef.current!, selectAddressRef.current!);
    sendCoverageChangeEvent(
      `${availableStore?.stores[0].id}`,
      `${newStoreRef.current!.stores[0].id}`,
      location,
      selectAddressRef.current!,
      availableStore.id!,
      newStoreRef.current!.id
    );
    setOpenModalItemsOutStock(false);
  };

  useEffect(() => {
    if (cartAvailables && newStoreRef.current && selectAddressRef.current && availableStore?.id) {
      if (cartAvailables.payload.length <= 0 || cartAvailables.payload.some((item) => !item.is_available)) {
        // Product dont availables
        setOpenModalItemsOutStock(true);
        return;
      }

      // Products availables
      handleOnSubmitItemOutStock();
    }
  }, [cartAvailables, newStoreRef.current, selectAddressRef.current, availableStore?.id]);

  useEffect(() => {
    if (isOpen) {
      itemsRef.current = items;

      return () => {
        itemsRef.current = [];
      };
    }
  }, [isOpen]);

  return (
    <>
      <Modal
        showCenter
        isOpen={isOpen}
        handleClose={handleClose}
        closeOutSise={!showLocationModal}
        classes={{
          card: tw`p-0 w-[92%] max-w-[32rem] sm:max-w-max`,
          backdrop: tw`z-[52]`,
        }}
      >
        <StyledWrapperAddresForm>
          <StyledSectionSelect>
            <StyledWrapperHeader>
              <Heading type="h5">¿Dónde deseas recibir tu compra?</Heading>
              {!showLocationModal && <IconClose tw="h-6 w-6 cursor-pointer" onClick={handleClose} />}
            </StyledWrapperHeader>
            <Paragraph sizeVariant="md">
              Elegir tu ubicación nos permite mostrarte los productos disponibles y asegurar una rápida
              entrega.
            </Paragraph>
            <AddressSelectionCard
              setSelectAddress={onSubmit}
              listStores={listStores}
              location={location}
              isDefaultSelected={showLocationModal}
            />
          </StyledSectionSelect>

          <CityFeedbackForm
            isVisible={showCityFeedbackForm}
            handleVisible={handleCityFeedbackForm}
            onClose={handleClose}
            onSuccess={() => {
              if (listStores?.[0]?.city) {
                void onSubmit(listStores[0], false);
              }
            }}
          />

          {isLoading && (
            <StyledAddresFormLoader>
              {!succesChange && <StyledLoaderLoading />}
              {succesChange && <StyledLoaderCheked />}
            </StyledAddresFormLoader>
          )}
        </StyledWrapperAddresForm>
      </Modal>

      {openModalItemsOutStock && (
        <ModalItemsOutOfStock
          isOpen={openModalItemsOutStock}
          handleClose={() => setOpenModalItemsOutStock(false)}
          storeId={newStoreRef.current?.stores?.[0]?.id}
          onSubmit={handleOnSubmitItemOutStock}
        />
      )}
    </>
  );
};
export default ModalAddressForm;
