const NewLetter = () => {
  return (
    <div>
      <div className="klaviyo-form-Ximaid KlaviyoForm" />;
      <div className="klaviyo-form-Tyx4tm" tw="w-full"></div>
    </div>
  );
};

export default NewLetter;
