const CorrectCityNames = {
  bogota: "Bogotá",
  barranquilla: "Barranquilla",
  medellin: "Medellín",
};

export default CorrectCityNames;

export const getCorrectCityName = (cityKey: string) => {
  return CorrectCityNames[cityKey.toLowerCase()] || cityKey;
};
