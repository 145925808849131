import { Link } from "react-router-dom";
import tw from "twin.macro";
import Image from "@/components/Atoms/Image";
import Card from "@/components/Atoms/Card";
import { type IHub } from "@/types/hubs";

import { StyledFigureImage } from "./styles";
import ButtonText from "../ButtonText";

interface IHubCardProps {
  hub: IHub;
  [key: string]: any;
}

const HubCard = ({ hub, ...rest }: IHubCardProps) => {
  return (
    <Card
      to={`/h/${hub.slug}`}
      title={hub.name}
      as={Link}
      classes={{
        root: tw`shadow-elevation-0 text-center flex flex-col justify-start items-center gap-2 py-m px-2
        h-[6.5rem]  w-[6rem]  last:mr-10 medium:(py-4 px-3 min-h-[7rem] w-[8.063rem] ) large:(py-l px-4 h-[10rem]  last:mr-0 w-[9.063rem]) `,
      }}
      {...rest}
    >
      <StyledFigureImage>
        <Image src={hub.image.url} alt={hub.image.alt} title={hub.name} effect="blur" />
      </StyledFigureImage>

      <ButtonText sizeVariant="sm">{hub?.name}</ButtonText>
    </Card>
  );
};

export default HubCard;

//
