import { type CSSProperties } from "react";
import { type RuleSet } from "styled-components";
import tw, { css, styled, type TwStyle } from "twin.macro";

export const StyledWrapperCard = styled.div<{
  $classes?: RuleSet<object> | TwStyle;
  $withShadow?: boolean;
  $withZigzag?: boolean;
  $borderRadius?: CSSProperties["borderRadius"];
}>`
  ${tw`rounded-lg shadow-elevation-2 p-4`}
  ${({ $classes }) => $classes && $classes}
  ${({ $withShadow }) => {
    if (!$withShadow) return tw`shadow-none border border-neutral-90`;
  }}
  ${({ $withZigzag }) => {
    if ($withZigzag) return tw`rounded-b-none mb-[0.563rem]`;
  }}
  ${({ $borderRadius }) => {
    if ($borderRadius !== undefined) {
      return css`
        border-radius: ${$borderRadius}px !important;
      `;
    }
  }}
`;

export const StyledZigZag = styled.div`
  ${tw`w-full h-[0.625rem] bg-secondary-100 absolute bottom-[-0.5rem]`}

  --size: 0.563rem;
  --angle: 90deg;

  mask: conic-gradient(
      from calc(var(--angle) / -2) at bottom,
      #0000,
      #000 1deg calc(var(--angle) - 1deg),
      #0000 var(--angle)
    )
    50% / calc(2 * var(--size) * tan(var(--angle) / 2)) 100%;
`;
