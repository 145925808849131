import tw, { styled } from "twin.macro";
import IconChevron from "@/components/Icons/V2/Arrow/chevron-bottom-normal.svg?react";
import Paragraph from "@/components/Atoms/Paragraph";
import { type Status } from ".";
import type { TSize } from "@/types/common";
import applyResponsiveStyles from "@/utils/applyResponsiveStyles";

interface StyledStatusProps {
  $status: Status;
  $size?: TSize;
}

const styles = {
  wrapper: {
    size: {
      small: tw`p-s gap-s`,
      medium: tw`p-sm gap-sm`,
      large: tw`gap-m p-m`,
    },
  },
  input: {
    size: {
      small: tw`text-sm`,
      medium: tw`text-base`,
      large: tw`text-lg`,
    },
  },
};

export const StyledPhoneInputWrapper = styled.div<StyledStatusProps>`
  ${tw`grid grid-cols-[130px_max-content_auto] items-center gap-m border border-neutral-80 rounded-m p-m h-max my-s`}

  ${({ $status }) => $status === "error" && tw`border-error-40`}
  ${({ $status }) => $status === "success" && tw`border-tertiary-40`}


  ${({ $size }) =>
    applyResponsiveStyles({
      size: $size,
      stylesBreakpoints: styles.wrapper.size,
    })}
`;

export const StyledPhoneInput = styled.input<StyledStatusProps>`
  ${tw`w-full`}

  ${({ $status }) => $status === "error" && tw`text-error-40`}
  ${({ $status }) => $status === "success" && tw`text-tertiary-40`}

  ${({ $size }) =>
    applyResponsiveStyles({
      size: $size,
      stylesBreakpoints: styles.input.size,
    })}
`;
export const StyledLined = styled.div`
  ${tw`h-full w-0.5 border-r border-neutral-90`}
`;

export const StyledIconChevronDown = styled(IconChevron)<{ $open: boolean }>`
  ${tw`transition-transform duration-300 ease-in-out transform`}
  ${({ $open }) => ($open ? tw`rotate-180` : tw`rotate-0`)}

  & path {
    ${tw`fill-current`}
  }
`;
export const StyledSupportText = styled(Paragraph)<StyledStatusProps>`
  ${({ $status }) => $status === "error" && tw`text-error-40`}
  ${({ $status }) => $status === "success" && tw`text-tertiary-40`}
`;
