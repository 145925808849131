import { forwardRef } from "react";
import TextField, { type ITextFieldProps } from "@atoms/TextField";
import { StyledWrapperInput, StyledText, StyledLabel } from "./styles";

export interface IInputProps extends ITextFieldProps {
  label: string; // If you do not need a label, please use Atoms/TextField.
  textHelp?: string;
}

const Input = forwardRef<HTMLInputElement, IInputProps>(
  ({ label, textHelp, fullWidth = true, ...props }, ref) => {
    return (
      <StyledWrapperInput $fullWidth={fullWidth}>
        <StyledLabel htmlFor={props.id} size={props.size} $disabled={props.disabled}>
          {label ?? ""}
        </StyledLabel>
        <TextField {...props} fullWidth={fullWidth} ref={ref} />
        {textHelp && (
          <StyledText
            sizeVariant="sm"
            $disabled={props.disabled}
            $success={props.success}
            $errors={props.errors}
          >
            {textHelp}
          </StyledText>
        )}
      </StyledWrapperInput>
    );
  }
);

export default Input;
