import { useState } from "react";
import tw, { theme } from "twin.macro";
import Popover, { PopoverContent, PopoverTrigger } from "@atoms/Popover";
import LogoFithubPrimary from "@iconsV2/Brand/primary.svg?react";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import HamburguerButton from "@/components/Atoms/HamburgerButton";
import { Link } from "react-router-dom";
import Avatar from "@/components/Atoms/Avatar";

import useAppSelector from "@/hooks/useAppSelector";
import IconChevronRight from "@iconsV2/Arrow/chevron-right-normal.svg?react";
import ButtonText from "@/components/Atoms/ButtonText";
import Ciity from "@/components/Atoms/Ciity";
import ShoppingCart from "@/components/Organisms/ShoppingCart";
import Button from "@/components/Atoms/Button";
import IconUser from "@iconsV2/Users/user-circle.svg?react";
import useModalAuth from "@/hooks/useModalAuth";
import useAddressModal from "@/hooks/useAddressModal";
import ProfileMenu from "../ProfileMenu";

interface IHeaderMenu {
  open: boolean;
  handleOpen: (value: boolean) => void;
  isScrolled: boolean;
}

const HeaderMenu = ({ open, handleOpen, isScrolled }: IHeaderMenu) => {
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const isMedium = useMediaQuery("( min-width: {medium} )");
  const isLarge = useMediaQuery("( min-width: {large} )");

  const { handleOpenModalAuth } = useModalAuth();
  const { isOpen: isAddressModalOpen, toggleAddressModal } = useAddressModal();

  const user = useAppSelector((state) => state.user);
  const nameUser = `${user?.firstName} ${user?.lastName}`;

  const handleOpenProfileMenu = (open: boolean) => {
    setOpenProfileMenu(open);
  };

  const handleToggleOpen = () => {
    handleOpen(!open);
  };

  return (
    <Popover
      placement="bottom-start"
      onOpenChange={handleToggleOpen}
      open={open}
      hidden={false}
      fullScreen={!isMedium}
    >
      <PopoverTrigger>
        <HamburguerButton open={open} handleOpen={handleToggleOpen} />
      </PopoverTrigger>

      <PopoverContent
        classes={{
          root: tw`flex flex-col z-[52] w-full bg-secondary-100 !p-0 all-child:!w-full medium:(w-[346px]) large:(w-[396px])`,
        }}
      >
        <ProfileMenu
          nameUser={nameUser}
          openMenu={openProfileMenu}
          handleOpenMenu={handleOpenProfileMenu}
          placement="right-start"
          onCloseLogout={handleToggleOpen}
        >
          <div tw="flex flex-col gap-m mt-s p-m">
            {!isMedium && (
              <div tw="w-full flex justify-between items-center">
                <div tw="flex items-center gap-s">
                  <HamburguerButton open handleOpen={handleToggleOpen} />

                  <Link to="/" title="Fithub" onClick={handleToggleOpen}>
                    <LogoFithubPrimary tw="w-[4.625rem] h-[1.5rem]" />
                  </Link>
                </div>

                <ShoppingCart isScrolled={isScrolled} />
              </div>
            )}

            {(isLarge || (isMedium && isScrolled)) && (
              <Link to="/" title="Fithub" onClick={handleToggleOpen}>
                <LogoFithubPrimary tw="w-[5.375rem] h-[1.5rem]" />
              </Link>
            )}

            {user?.firstName ? (
              <button
                tw="w-full bg-neutral flex items-center justify-between gap-xxs rounded-s py-sm px-m large:(py-m)"
                onClick={() => handleOpenProfileMenu(true)}
              >
                <div tw="flex items-center gap-sm">
                  <Avatar
                    size="large"
                    label={nameUser}
                    backgroundColor={theme`colors.secondary.100`}
                    color={theme`colors.neutral.10`}
                  />

                  <ButtonText tw="text-left" sizeVariant="md" color={theme`colors.secondary.100`}>
                    {user?.firstName} {user?.lastName}
                  </ButtonText>
                </div>

                <IconChevronRight tw="w-ml h-ml all-child:fill-secondary-100" />
              </button>
            ) : (
              <Button
                sizeVariant="lg"
                color="secondary"
                variant="outlined"
                onClick={() => {
                  handleOpenModalAuth({ initialStep: "login" });
                  handleToggleOpen();
                }}
                endIcon={<IconUser tw="fill-secondary-50" />}
                tw="justify-between"
              >
                Ingresar
              </Button>
            )}

            {/* Categories most used */}
            {/* <hr />
           <div tw="flex flex-col gap-ml">
            <Heading type="h6">Tus categorías más visitadas</Heading>

            <ButtonText
              as={Link}
              color={theme`colors.secondary.50`}
              to="/c/nuestra-marca"
              sizeVariant="lg"
              onClick={handleToggleOpen}
              title="Nuestra marca"
            >
              Nuestra marca
            </ButtonText>

            <ButtonText
              as={Link}
              color={theme`colors.secondary.50`}
              to="/c/promofit"
              sizeVariant="lg"
              onClick={handleToggleOpen}
              title="Promofit"
            >
              Promofit
            </ButtonText>

            <ButtonText
              as={Link}
              label="Nuevos"
              color={theme`colors.secondary.50`}
              to="/c/nuevos"
              sizeVariant="lg"
              onClick={handleToggleOpen}
              title="Nuevos"
            >
              Nuevos
            </ButtonText>
          </div> */}

            <hr />

            <div tw="flex flex-col gap-ml">
              {/* <ButtonText color={theme`colors.secondary.50`} sizeVariant="lg" as={Link} to="/gift-cards/redeem">
              Tarjeta regalo
            </ButtonText> */}

              <ButtonText
                color={theme`colors.secondary.50`}
                sizeVariant="lg"
                as={Link}
                to="/gift-cards/redeem"
                onClick={handleToggleOpen}
                title="Canjea tu regalo"
              >
                Canjea tu regalo
              </ButtonText>

              <ButtonText
                to={"https://blog.fithub.com.co"}
                target="_blank"
                rel="noopener noreferrer"
                color={theme`colors.secondary.50`}
                as={Link}
                sizeVariant="lg"
                onClick={handleToggleOpen}
                title="Blog"
              >
                Blog
              </ButtonText>
            </div>

            <hr />

            <div
              tw="w-full border border-neutral-90 rounded-s all-child:w-full medium:(rounded-m) large:(rounded-lg)"
              onClick={handleToggleOpen}
            >
              <Ciity
                open={isAddressModalOpen}
                handleChangeOpen={toggleAddressModal}
                withPopover={false}
                textDirection="horizontal"
                fullWidth
              />
            </div>
          </div>
        </ProfileMenu>
      </PopoverContent>
    </Popover>
  );
};

export default HeaderMenu;
