import { Navigate, Outlet, type RouteObject } from "react-router-dom";
import { wrapCreateBrowserRouter } from "@sentry/react";

import MainLayout from "@/components/Layouts/MainLayout";
import RootLayout from "@/components/Layouts/RootLayout";
import SentryRouteErrorFallback from "@/components/Layouts/SentryRouteErrorFallback";
import { IS_MAINTENANCE, IS_PROD } from "@/recourses/constants";

import { PrivateRoute } from "./PrivateRoute";
import { checkoutRoutes, maintenanceRoutes } from "./routes";
import publicRoutes from "./public";
import privateRoutes from "./private";

import { createBrowserRouterWithRetry } from "./utils";
import newRoutes from "./new";
import Error404 from "@/Pages/Error404";
import CheckoutLayout from "@/components/Layouts/CheckoutLayout";

const routes: RouteObject[] = IS_MAINTENANCE
  ? [
      ...maintenanceRoutes,
      {
        path: "*",
        element: <Navigate to="/" replace />,
      },
    ]
  : [
      {
        path: "/",
        element: <RootLayout />,
        errorElement: IS_PROD && <SentryRouteErrorFallback />,
        children: [
          ...publicRoutes,
          {
            path: "/",
            element: (
              <PrivateRoute>
                <Outlet />
              </PrivateRoute>
            ),
            children: privateRoutes,
          },
          {
            path: "/",
            Component: MainLayout,
            children: newRoutes,
          },
          {
            path: "/checkout/",
            Component: CheckoutLayout,
            element: (
              <PrivateRoute>
                <Outlet />
              </PrivateRoute>
            ),
            children: checkoutRoutes,
          },
          {
            path: "*",
            element: <Error404 />,
          },
        ],
      },
    ];

export const wrapperBrowserRouter = wrapCreateBrowserRouter(createBrowserRouterWithRetry);

export default wrapperBrowserRouter(routes);
