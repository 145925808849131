export const enum PaymentMethodsEnum {
  paymentMethod = "paymentMethod",
  paymentMethodsAvailable = "paymentMethodsAvailable",
  paymentPseUrl = "paymentPseUrl",
}

export const enum CreditsEnum {
  creditsAvailable = "creditsAvailable",
}

export const enum productsDetailsEnum {
  getProductQuestions = "getProductQuestions",
  getProductReviews = "getProductReviews",
  getProductInfiniteReviews = "getProductInfiniteReviews",
  getProductStatistics = "getProductStatistics",
}

export const enum CheckoutEnum {
  getCartAvailableProducts = "getCartAvailableProducts",
}

export const enum MembershipEnum {
  listMemebership = "listMemebership",
}

export const enum SearchBarEnum {
  searchResults = "searchResults",
  searchSuggests = "searchSuggests",
}
