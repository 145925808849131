import tw, { styled } from "twin.macro";

export const StyledLoaderRoot = styled.div`
  ${tw`flex flex-col justify-center items-center gap-2 h-[5.5rem] w-[5.375rem] medium:(h-[6.875rem] w-[7.625rem]) large:(h-[9.813rem] w-[7.938rem]) animate-pulse1 shadow-elevation-0`}
`;

export const StyledCard = styled.div`
  ${tw`w-full h-full flex flex-col items-center justify-center gap-4 bg-secondary-99 rounded-l`}
`;

export const StyledLogo = styled.div`
  ${tw`w-[1.875rem] h-[1.875rem] medium:(w-[2.5rem] h-[2.5rem]) large:(w-[3.5rem] h-[3.5rem]) rounded-full bg-secondary-70`}
`;

export const StyledLabel = styled.div`
  ${tw`h-[0.65rem] w-[2.5rem] medium:(h-[0.75rem] w-[3.8rem]) large:(h-[0.80138rem] w-[4rem]) rounded bg-secondary-60`}
`;
