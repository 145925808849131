import Input from "@/components/Molecules/Input";
import Button from "@/components/Atoms/Button";
import Paragraph from "@/components/Atoms/Paragraph";
import { type FnCloseModal } from "@/components/Organisms/ModalAddressForm";
import { type FormEvent, useRef, useState } from "react";
import useGoogleReCaptchaV3 from "@/hooks/useGoogleReCaptchaV3";
import { SITEKEYCAPTCHA } from "@/recourses/constants";
import { sendWaitingList } from "@/services/common/waitingList";
import { useMutation } from "@tanstack/react-query";
import CheckLocation from "@iconsV2/Illustration/checkLocation.svg?react";
import { StyledCityFeedbackForm, StyledCityFeedbackSuccess } from "./styles";

interface WaitingListData {
  city?: string | null;
  email?: string | null;
  recaptcha_token: string;
}

interface CityFeedbackFormProps {
  isVisible: boolean;
  handleVisible: (open: boolean) => void;
  onClose: FnCloseModal;
  onSuccess: () => void;
}

interface Errors {
  city?: string;
  email?: string;
  recaptcha_token?: string;
  detail: string;
}

const CityFeedbackForm = ({ isVisible, handleVisible, onClose, onSuccess }: CityFeedbackFormProps) => {
  const { executeReCaptcha } = useGoogleReCaptchaV3({
    siteKey: SITEKEYCAPTCHA,
    shouldLoad: isVisible,
  });

  const form = useRef<HTMLFormElement | null>(null);
  const [errors, setErrors] = useState<Errors>({});
  const [loading, setLoading] = useState(false);
  const [successFeedback, setSuccessFeedback] = useState<boolean>(false);

  const mutation = useMutation({
    mutationFn: async (data: WaitingListData) => await sendWaitingList(data),
    onSuccess: () => {
      handleVisible(false);
      setSuccessFeedback(true);
      onSuccess();
      setLoading(false);
    },
    onError: (errors: Errors) => {
      setLoading(false);
      setErrors(errors?.data);
    },
  });

  async function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (!form.current) return;

    setLoading(true);
    const formData = new FormData(form.current);
    const recaptchaToken = await executeReCaptcha("submit");

    if (recaptchaToken) {
      const data: WaitingListData = {
        city: formData.get("city") as string | null,
        email: formData.get("email") as string | null,
        recaptcha_token: recaptchaToken,
      };

      mutation.mutate(data);
    }
  }

  function onChange(type: "city" | "email") {
    if (type === "city") {
      const { city, ...rest } = errors;
      setErrors(rest);
    } else if (type === "email") {
      const { email, ...rest } = errors;
      setErrors(rest);
    }
  }

  const handleOpenForm = () => {
    handleVisible(true);
    setSuccessFeedback(false);
  };

  return (
    <>
      {!isVisible && (
        <div tw="px-m flex justify-center w-full mb-m">
          <Button
            color="secondary"
            sizeVariant="md"
            variant="text"
            type="submit"
            onClick={handleOpenForm}
            fullWidth
          >
            ¿Te encuentras en otra ciudad?
          </Button>
        </div>
      )}

      {successFeedback && (
        <StyledCityFeedbackSuccess>
          <CheckLocation />

          <div tw="flex flex-col text-center items-center gap-s">
            <Paragraph sizeVariant="lg">¡Gracias por tu información!</Paragraph>

            <Paragraph sizeVariant="md">
              Te mostramos los productos disponibles para Barranquilla y te contactaremos cuando expandamos a
              nuevas ciudades.
            </Paragraph>
          </div>

          <div>
            <Button color="secondary" sizeVariant="lg" variant="filled" onClick={onClose}>
              Entendido
            </Button>
          </div>
        </StyledCityFeedbackSuccess>
      )}

      {isVisible && (
        <StyledCityFeedbackForm onSubmit={onSubmit} ref={form}>
          <Paragraph sizeVariant="lg">
            Cuéntanos dónde estás y nosotros te avisamos cuando tengamos cobertura allí.
          </Paragraph>
          <Input
            type="text"
            label="¿En qué ciudad te encuentras?"
            placeholder="Ciudad de domicilio"
            size="medium"
            required
            name="city"
            errors={errors?.city ? true : undefined}
            textHelp={errors?.city}
            onChange={() => onChange("city")}
          />
          <Input
            type="email"
            label="¿Cuál es tu correo para poder avisarte cuando nuestra cobertura llegue allí?"
            placeholder="Ingresa tu correo"
            size="medium"
            autoComplete="email"
            required
            name="email"
            errors={errors?.email ? true : undefined}
            textHelp={errors?.email}
            onChange={() => onChange("email")}
          />
          <div>
            <Button color="secondary" sizeVariant="lg" variant="filled" type="submit" disabled={loading}>
              Enviar
            </Button>
          </div>
          {errors.detail && typeof errors.detail === "string" && (
            <Paragraph sizeVariant="sm" tw="text-error-40">
              {errors.detail}
            </Paragraph>
          )}
        </StyledCityFeedbackForm>
      )}
    </>
  );
};

export default CityFeedbackForm;
