import { useState, type ReactNode } from "react";
import tw, { type TwStyle } from "twin.macro";
import { type RuleSet } from "styled-components";
import AddIcon from "@/components/Icons/V2/Edit/add.svg?react";
import RemoveIcon from "@/components/Icons/V2/Edit/remove.svg?react";
import Card from "../Card";
import ButtonText from "../ButtonText";
import { StyledContentClose, StyledDivider, StyledHeading, StyledSummary } from "./styles";

export interface ICardDismissible {
  title?: string | ReactNode;
  children?: ReactNode;
  defaultOpen?: boolean;
  contentClose?: ReactNode;
  withShadow?: boolean;
  classes?: {
    root?: RuleSet<object> | TwStyle;
  };
  key?: any;
}

const CardDismissible = ({
  title,
  classes,
  children,
  defaultOpen,
  contentClose,
  withShadow = false,
}: ICardDismissible) => {
  const [expanded, setExpanded] = useState(defaultOpen ?? false);

  return (
    <Card classes={classes} withShadow={withShadow}>
      <details open={defaultOpen} onToggle={(e) => setExpanded((e.currentTarget as HTMLDetailsElement).open)}>
        <StyledSummary>
          <StyledHeading>
            {title && <ButtonText sizeVariant="md">{title}</ButtonText>}

            {expanded ? <RemoveIcon /> : <AddIcon />}
          </StyledHeading>
        </StyledSummary>

        {children}
      </details>
      {contentClose && !expanded && (
        <StyledContentClose>
          <StyledDivider />

          {contentClose}
        </StyledContentClose>
      )}
    </Card>
  );
};

export default CardDismissible;
