import Card from "@/components/Atoms/Card";
import IconOtra from "@iconsV2/Illustration/otra.svg?react";
import tw from "twin.macro";
import RadioButton from "@/components/Atoms/Radiobutton";
import ProductCardTag from "@/components/Atoms/ProductCardTag";

interface AddressSelectionCardProps {
  listStores: CityType[] | undefined;
  location: LocationType;
  setSelectAddress: (city: CityType) => void;
  isDefaultSelected?: boolean;
}
const AddressSelectionCard = ({
  listStores,
  location,
  setSelectAddress,
  isDefaultSelected,
}: AddressSelectionCardProps): JSX.Element => {
  return (
    <Card
      classes={{
        root: tw`shadow-elevation-0 grid grid-cols-[auto,1fr] gap-4`,
      }}
    >
      <IconOtra tw=" w-12 min-[375px]:(w-20)  md:w-28 h-full " />
      <div>
        {listStores?.map((city: CityType, index: number) => {
          const isSelected = location?.city?.toLowerCase() === city.code.toLowerCase();

          return (
            <div tw="relative border-b last:border-none" key={index}>
              <RadioButton
                name="city"
                color="primary"
                id="city-option"
                label={city.name}
                size="medium"
                onChange={() => {
                  setSelectAddress(city);
                }}
                classes={{
                  root: tw`p-2 w-full`,
                }}
                checked={!isDefaultSelected ? isSelected : undefined}
              />
              {city.is_new && (
                <div tw="absolute top-2 right-0">
                  <ProductCardTag type="new" />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </Card>
  );
};
export default AddressSelectionCard;
