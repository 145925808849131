import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../store";
import { cartStoresSlice } from "../store/cartStore";

type useCartInfoType = Pick<CartStoreAttr, "items" | "storeId" | "last_change_items">;

export interface useCartInfoResult {
  cart: useCartInfoType;
  actions: {
    setStoreId: (newStoreId: number) => void;
    setCommentToProduct: (pk: string, comment: string) => void;
    getCommentToProduct: (pk: string) => string;
    setQuantityProduct: (pk: string, quantity: number) => void;
    setCartStore: (newProduct: CartInput) => void;
    cleanCart: () => void;
    changeProductStore: (pkSearched: string, newProductInfo: CartInput) => void;
  };
}

export const useCartInfo = (): useCartInfoResult => {
  const dispatch = useDispatch();
  const cart = useTypedSelector((state) => state.cart);

  const [cartInfo, setCartInfo] = useState<useCartInfoType>({
    storeId: undefined,
    items: [],
    last_change_items: undefined,
  });

  useEffect(() => {
    setCartInfo({
      storeId: cart.storeId,
      items: cart.items,
      last_change_items: cart.last_change_items,
    });
    // eslint-disable-next-line
  }, [cart]);

  const getCommentToProduct = (pk: string): string => {
    const comment = cart.items.find((item) => item.pk === pk)?.comment;
    return comment ?? "";
  };

  const setStoreId = (newStoreId: number): void => {
    dispatch(cartStoresSlice.actions.setStoreId(newStoreId));
  };

  const setCommentToProduct = (pk: string, comment: string): void => {
    dispatch(
      cartStoresSlice.actions.setCommentToProduct({
        _pk: pk,
        _comment: comment,
      })
    );
  };

  const setCartStore = (product: CartInput): void => {
    dispatch(cartStoresSlice.actions.setCartStores(product));
  };

  const setQuantityProduct = (pk: string, quantity: number): void => {
    dispatch(
      cartStoresSlice.actions.setQuantityProduct({
        _pk: pk,
        quantity,
      })
    );
  };

  const cleanCart = (): void => {
    dispatch(cartStoresSlice.actions.emptyCart());
  };

  const changeProductStore = (pkSearched: string, newProductInfo: CartInput): void => {
    dispatch(
      cartStoresSlice.actions.changeProductStore({
        pkSearched,
        newProductStore: newProductInfo,
      })
    );
  };

  return {
    cart: cartInfo,
    actions: {
      getCommentToProduct,
      changeProductStore,
      setStoreId,
      setCommentToProduct,
      setCartStore,
      setQuantityProduct,
      cleanCart,
    },
  };
};
