import { useState, useEffect } from "react";

/**
 * @example
 * const endTime = 300; // seconds
 *
 * const count = useCountDown(endTime, () => {
 *   sideEffect();
 * });
 */
export const useCountDown = (endTime: number, onEnd?: () => void) => {
  const [count, setCount] = useState(endTime);

  useEffect(() => {
    const id =
      count === 0
        ? onEnd === undefined
          ? undefined
          : onEnd()
        : window.setTimeout(() => setCount(count - 1), 1000);

    return (): void => (id === undefined ? undefined : clearTimeout(id));
  }, [count, onEnd]);

  const reset = (): void => setCount(endTime);
  return [count, reset] as const;
};
