/* eslint-disable @typescript-eslint/explicit-function-return-type */
const locale = import.meta.env.REACT_APP_LOCALE;
const currency = import.meta.env.REACT_APP_CURRENCY || "COP";

export const formatLocaleString = (value: number): string => {
  return value
    .toLocaleString(locale, { style: "currency", currency, minimumFractionDigits: 0 })
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export function formatCurrency(value: number): string {
  // Redondear a dos decimales
  const roundedValue = Math.round(value * 100) / 100;

  // Separar la parte entera y decimal
  const [integerPart, decimalPart] = roundedValue.toFixed(2).split(".");

  // Formatear la parte entera con separadores de miles
  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  // Combinar las partes y agregar el símbolo de peso
  let result = `$${formattedIntegerPart}`;

  // Agregar la parte decimal solo si no es cero
  if (decimalPart !== "00") {
    result += `,${decimalPart}`;
  }

  return result;
}

export function calculatePercentageDiscount({
  priceDiscount,
  priceOriginal,
}: {
  priceDiscount: number;
  priceOriginal: number;
}): number {
  const price = Number(priceDiscount ?? 0);
  const originalPrice = Number(priceOriginal ?? 0);

  if (originalPrice === 0 || price >= originalPrice) {
    return 0;
  }

  const result = (originalPrice - price) / originalPrice;
  return Math.round(result * 100);
}
