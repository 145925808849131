import http from "@/utils/http";
import { request } from "../../utils/request";
import * as authUrl from "@/recourses/urls/auth";

const BASE_API_URL = import.meta.env.REACT_APP_BASE_API_URL;
const AUTH_PHONE_ENDPOINT = "auth/telephone/";
const AUTH_PHONE_VALIDATION_ENDPOINT = "auth/telephone/callback/";
const AUTH_REGISTRATION_ENDPOINT = "auth/telephone/registration/";
const AUTH_CHANGE_PASSWORD_ENDPOINT = "auth/telephone/change-password/";
const AUTH_LOGIN_ENDPOINT = "auth/login/";
const AUTH_VERIFY = "auth/verify/";
const USER_SETTINGS = "settings/";

const createPhoneCode = async ({
  phoneNumber,
  type,
  channel,
}: {
  phoneNumber: string;
  type: "new_user" | "change_password";
  channel: "sms" | "whatsapp";
}): Promise<{
  message: string;
}> => {
  const response = await request<{ message: string }>(`${BASE_API_URL}api/v1/${AUTH_PHONE_ENDPOINT}`, {
    method: "POST",
    data: {
      telephone: phoneNumber,
      type,
      channel,
    },
  });
  return response.data;
};

const validatePhoneCode = async ({
  phoneNumber,
  code,
}: {
  phoneNumber: string;
  code: string;
}): Promise<{
  token: string;
}> => {
  const response = await request<{ token: string }>(
    `${BASE_API_URL}api/v1/${AUTH_PHONE_VALIDATION_ENDPOINT}`,
    {
      method: "POST",
      data: {
        telephone: phoneNumber,
        code,
      },
    }
  );
  return response.data;
};

const createUser = async ({
  email,
  password,
  firstName,
  lastName,
  birthday,
  token,
  gender,
  hearAboutUs,
  identification,
  identificationType,
}: createUserProps): Promise<UserAttr> => {
  const response = await request<UserAttr>(`${BASE_API_URL}api/v1/${AUTH_REGISTRATION_ENDPOINT}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      email: email.toLowerCase(),
      password,
      gender,
      first_name: firstName,
      last_name: lastName,
      dob: birthday,
      hear_about_us_source: hearAboutUs,
      identification,
      identification_type: identificationType,
    },
  });
  return response.data;
};

const loginUser = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<{ token: string; user: Omit<UserAttr, "token"> }> => {
  const response = await request<{
    token: string;
    user: Omit<UserAttr, "token">;
  }>(`${BASE_API_URL}api/v1/${AUTH_LOGIN_ENDPOINT}`, {
    method: "POST",
    data: {
      email: email.toLowerCase(),
      password,
    },
  });

  return response.data;
};

const changePassword = async ({
  newPassword,
  token,
}: {
  newPassword: string;

  token: string;
}): Promise<{
  message: string;
}> => {
  const response = await request<{
    message: string;
  }>(`${BASE_API_URL}api/v1/${AUTH_CHANGE_PASSWORD_ENDPOINT}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      password: newPassword,
      confirm_password: newPassword,
    },
  });

  return response.data;
};

const setUserSettings = async ({
  hearAboutUs,
  gender,
  dob,
  identification,
  identificationType,
}: setUserSettingsParams): Promise<any> => {
  const response = await request<{
    message: string;
  }>(`${BASE_API_URL}api/v1/${USER_SETTINGS}`, {
    method: "PATCH",
    data: {
      ...(hearAboutUs ? { hear_about_us_source: hearAboutUs } : {}),
      ...(gender ? { gender } : {}),
      ...(dob ? { dob } : {}),
      ...(identification && identificationType ? { identification } : {}),
      ...(identification && identificationType ? { identification_type: identificationType } : {}),
    },
  });

  return response.data;
};

const getUserSettings = async ({ token }: any): Promise<{ token: string; user: Omit<UserAttr, "token"> }> => {
  const response = await request<{
    token: string;
    user: Omit<UserAttr, "token">;
  }>(`${BASE_API_URL}api/v1/${AUTH_VERIFY}`, {
    method: "POST",
    data: {
      token,
    },
  });
  return response.data;
};

export const verifyTokenJWT = async () => {
  const token = window.localStorage.getItem("userToken");
  if (!token) {
    throw new Error("Token not found");
  }

  const response = await http.post(
    authUrl.verifyTokenJWT,
    {
      token: JSON.parse(token),
    },
    { isAuth: false }
  );
  return response.data;
};

export {
  getUserSettings,
  createPhoneCode,
  validatePhoneCode,
  createUser,
  loginUser,
  changePassword,
  setUserSettings,
};
