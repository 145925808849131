import moment from "moment";
import { useMutation, useQuery } from "react-query";
import React, { createContext, useState, useReducer, useEffect, useContext } from "react";

import { sessionContext } from "../../context";
import { v4 } from "../../utils/public-ip";
import { useTypedSelector } from "../../store";
import { getDeliveryPrice } from "../../services/price";
import { getCreditsAvailable, getReferralCreditsAvailable } from "../../services/credits";
import { isValidateServicePrice, cleaningNullValue } from "../../utils/orders";
import { useMembership } from "../../hooks";
import { toast } from "react-toastify";
import { validateCoupon } from "../../services/coupons";
import { useCartInfo } from "../../hooks/useCartInfo";
import { getProductsSummaryInfo, getService } from "./utils";

const checkoutEmpty: ICheckout = {
  coupon: null,
  service: null,
  pickupStore: null,
  payment: null,
  useGift: false,
  useFitpoint: null,
  useFriendPoints: null,
  sendWhatsApp: true,
  gift_from: "",
  gift_for: "",
  gift_comment: "",
  useBasket: false,
  basket_from: "",
  basket_for: "",
  basket_comment: "",
  additionalComment: "",
};

const CheckoutContextEmpty = {
  checkout: checkoutEmpty,
  date: "",
  subtotal: 0,
  discount: 0,
  fitpoints: undefined,
  isDisabledNextStep: true,
  stepNumber: 0,
};

interface CheckoutProviderProps {
  children: React.ReactNode;
}

const checkoutContext = createContext<CheckoutContext>(CheckoutContextEmpty);

const reducer = (state: ICheckout, pair): ICheckout => ({ ...state, ...pair });

const CheckoutProvider = ({ children }: CheckoutProviderProps): React.ReactElement => {
  /* Use useReducer tu update context in real time  */
  const [checkout, setCheckout] = useReducer(reducer, checkoutEmpty);

  const location = useTypedSelector((state) => state.cart).location;
  const { cart } = useCartInfo();
  const [hasMembership] = useMembership();
  const { userToken } = useContext(sessionContext);
  const isLoggedIn = !(userToken === null);

  // Context States
  const [nextStep, setNextStep] = useState<string | (() => void)>("");
  const [isDisabledNextStep, setIsDisabledNextStep] = useState<boolean>(true);
  const [stepNumber, setStepNumber] = useState<number>(1);
  const [shippingServicePrice, setShippingServicePrice] = useState<getDeliveryPriceResponse | undefined>(
    undefined
  );
  const [optionFlow, setOptionFlow] = useState<"Delivery" | "Pick up">((): "Delivery" | "Pick up" => {
    return !!location && !!location.isPickUp ? "Pick up" : "Delivery";
  });

  // web navigation data
  const user_agent = navigator.userAgent;
  const ip = useQuery("ip", v4);
  const ip_address = ip.data;

  // get service delivery date
  let date: string | null = null;

  // set date if service is delivery
  if (checkout?.service?.day && checkout.service?.hours) {
    date = moment(
      `${moment(checkout.service.day?.date).format("YYYY-MM-DDT") + checkout.service.hours.start}`
    ).format("YYYY-MM-DDTHH:mm:ssZ");
  }

  // usefull queries to get data

  // fitpoints
  const { data: fitpoints } = useQuery("creditsAvailable", getCreditsAvailable, {
    enabled: isLoggedIn,
  });

  // friendpoint
  const { data: friendpointInfo } = useQuery("referralCreditsAvailable", getReferralCreditsAvailable, {
    enabled: isLoggedIn,
  });

  //  mutation to getService delivery price
  const [getServicePrice, { isLoading: isLoadingGetServicePrice }] = useMutation(getDeliveryPrice, {
    onSuccess: (DeliveryPriceData: getDeliveryPriceResponse) => {
      setShippingServicePrice(DeliveryPriceData);
    },
    onError: () => {
      toast.error("Error al calcular el precio de envio", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setShippingServicePrice(undefined);
    },
  });

  // get Purchase summary info
  const {
    productsSubtotal, // Get products subtotal without discounts
    productDiscoutedTotal, // Get products total with discounts (regular and membership)
    productsDiscount, // Get products discount (regular and membership)
    creditsSummary, // Get credits summary (FitPoints)
    referralCreditsSummary, // Get referral credits summary (FriendPoints)
    couponDiscount, // Get coupon discount
    ProductsTotal, // Get products total with discounts (regular and membership), credits and coupon
  } = getProductsSummaryInfo(cart.items, fitpoints, friendpointInfo, checkout.coupon, {
    hasMembership,
    useCoupon: !!checkout.coupon,
    useFitpoints: !!checkout.useFitpoint,
    useFriendPoints: !!checkout.useFriendPoints,
  });
  const servicePrice = getService(shippingServicePrice);
  const total = ProductsTotal + (!!servicePrice && servicePrice > 0 ? servicePrice : 0); // + (checkout.useBasket ? + 10000 : 0)

  useEffect(() => {
    // get service Price use effect
    const servicePriceData = buildServicePriceData();
    if (isValidateServicePrice(servicePriceData)) {
      if (checkout.coupon) {
        (async () => {
          // Validate coupon
          !!cart.storeId &&
            !!checkout.coupon &&
            validateCoupon(checkout.coupon.code, cart.storeId, productsSubtotal)
              .then(() => {
                // if coupon is valid, get coupon discount
                getServicePrice(buildServicePriceData());
              })
              .catch(() => {
                // if coupon is not valid, remove coupon and get service price
                setCheckout(Object.assign(checkout, { coupon: null }));
                getServicePrice(buildServicePriceData());
              });
        })();
      } else {
        // if there is no coupon, get service price
        getServicePrice(buildServicePriceData());
      }
    } else {
      setShippingServicePrice(undefined);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkout, cart.items, cart.storeId]);

  useEffect(() => {
    if (checkout.coupon) {
      // if has coupon, valid on any change on checkout and add products
      (async () => {
        !!cart.storeId &&
          !!checkout.coupon &&
          validateCoupon(checkout.coupon.code, cart.storeId, productsSubtotal).catch(() => {
            setCheckout(Object.assign(checkout, { coupon: null }));
          });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkout, cart.items, cart.storeId]);

  // Auxiliar functions
  const buildServicePriceData = (): any => {
    const fields = cleaningNullValue({
      id: checkout.service?.item.id,
      scheduled_at: date,
      total: Math.trunc(productDiscoutedTotal), // preguntar a Ale
      address: location?.id,
      coupon: checkout.coupon ? checkout.coupon.id : null,
      lat: location?.lat ? location.lat.toFixed(12).toString() : "",
      lng: location?.lng ? location.lng.toFixed(12).toString() : "",
      items: cart.items.map((productItem: Cart): number => {
        return productItem.details.sku;
      }),
    });

    return fields;
  };

  const isValidateNextStep = (callback): void => {
    if (!callback) return setIsDisabledNextStep(false);
    if (callback()) return setIsDisabledNextStep(false);
    return setIsDisabledNextStep(true);
  };

  const getOrderParams = (): CreateOrdenType => {
    return {
      shipping_address: checkout.service?.item.appointment_type === "on_site" ? null : location.id,
      items: cart.items.map((item) => ({
        store_product: String(item.details.sku),
        quantity: item.quantity,
        comment: item.comment ? item.comment : "",
        recommendation_id: item?.recommId ?? "",
      })),
      store: cart.storeId,
      coupon: checkout.coupon ? checkout.coupon.id : undefined,
      service: checkout.service ? checkout.service.item.id : undefined,
      user_payment_method: checkout.payment ? checkout.payment.id : undefined,
      should_use_credit_available: !!checkout.useFitpoint,
      should_use_referral_credit_available: !!checkout.useFriendPoints,
      scheduled_at: date,
      user_comment: checkout.additionalComment ? `${checkout.additionalComment}` : "",
      is_gift: checkout.useBasket ? false : checkout.useGift,
      gift_from: checkout.useGift && !checkout.useBasket && !!checkout.gift_from ? checkout.gift_from : null,
      gift_for: checkout.useGift && !checkout.useBasket && !!checkout.gift_for ? checkout.gift_for : null,
      gift_comment:
        checkout.useGift && !checkout.useBasket && !!checkout.gift_comment ? checkout.gift_comment : null,
      is_basket: checkout.useGift ? false : checkout.useBasket,
      basket_from:
        checkout.useBasket && !checkout.useGift && !!checkout.basket_from ? checkout.basket_from : null,
      basket_for:
        checkout.useBasket && !checkout.useGift && !!checkout.basket_for ? checkout.basket_for : null,
      basket_comment:
        checkout.useBasket && !checkout.useGift && !!checkout.basket_comment ? checkout.basket_comment : null,
      should_receive_notifications: checkout.sendWhatsApp,
      meta_data: {
        user_agent,
        ip_address: ip_address || "8.8.8.8",
      },
    };
  };

  return (
    <checkoutContext.Provider
      value={{
        optionFlow,
        setOptionFlow,
        isLoadingGetServicePrice,
        getOrderParams,
        productsTotal: productsSubtotal,
        shippingServicePrice,
        productsDiscount: productsDiscount.regularDiscounts,
        productsDiscountMembership: productsDiscount.membershipDiscounts,
        productsSubtotal: productDiscoutedTotal,
        servicePrice,
        couponDiscount,
        referralCreditsSummary,
        subtotal: total,
        creditsDiscount: creditsSummary.creditsAvailable,
        total,
        date,
        checkout,
        setCheckout,
        stepNumber,
        setStepNumber,
        fitpoints,
        nextStep,
        setNextStep,
        isDisabledNextStep,
        setIsDisabledNextStep,
        isValidateNextStep,
      }}
    >
      {children}
    </checkoutContext.Provider>
  );
};

export { checkoutContext, CheckoutProvider };
