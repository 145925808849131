import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import tw, { theme } from "twin.macro";
import Heading from "@atoms/Heading";
import Paragraph from "@atoms/Paragraph";
import Button from "@atoms/Button";
import Image from "@atoms/Image";
import DetailAmount from "@atoms/DetailAmount";
import CardDismissible from "@atoms/CardDismissible";
import { graphqlToProductGallery } from "@/utils/product";
import IconMastercard from "@iconsV2/Cart/mastercard.svg?react";
import IconVisa from "@iconsV2/Cart/visa.svg?react";
import IconDinersClub from "@iconsV2/Cart/diners-club.svg?react";
import IconCartShopping from "@iconsV2/Cart/cart-shopping.svg?react";
import srcPse from "@/assets/cart/pse.webp";
import srcLyra from "@/assets/cart/lyra.webp";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import useModalAuth, { CALLBACK_MODAL_AUTH } from "@/hooks/useModalAuth";
import useCart from "@/hooks/useCartV2";
import { formatCurrency } from "@/utils/money";
import ProductCardGallerySearch from "../ProductCardGalllerySearch";
import {
  StyledWrapper,
  StyledWrapperHeader,
  StyledWrapperBody,
  StyledWrapperProducts,
  StyledWrapperContent,
  StyledWrapperAmounts,
  StyledWrapperContainerScroll,
  StyledWrapperContentBottom,
} from "./styles";
import useAppSelector from "@/hooks/useAppSelector";
import ModalItemsOutOfStock from "@/components/Organisms/ModalItemsOutOfStock";
import useAddressModal, { CALLBACK_MODAL_ADDRESS } from "@/hooks/useAddressModal";
import { useKeepCartFresh } from "@/hooks/useKeepCartFresh";

interface IShoppingCartMenu {
  onAction: () => void;
  onClose: () => void;
}

const CartEmpty = () => {
  return (
    <div tw="flex flex-col items-center gap-s">
      <IconCartShopping tw="w-[9.188rem] h-[7.75rem]" />

      <Paragraph sizeVariant="md">Aún no tienes productos en tu canasta </Paragraph>
    </div>
  );
};

const ShoppingCartMenu = ({ onAction, onClose }: IShoppingCartMenu) => {
  useKeepCartFresh();

  const popUpsReminder = useAppSelector((state) => state.popUpsReminder.showLocationModal);

  const { toggleAddressModal } = useAddressModal();
  const scrollDivRef = useRef<HTMLDivElement | null>(null);
  const contentBottomRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const isMedium = useMediaQuery("( min-width: {medium} )");
  const { discount, subTotal, total, totalCountCart, items, itemsStock } = useCart();
  const { handleOpenModalAuth, isAthenticated } = useModalAuth();

  const availableStores = useAppSelector((state) => state.cart.availableStores.stores);
  const storeId = availableStores?.[0]?.id;

  const [openModalItemsOutStock, setOpenModalItemsOutStock] = useState<boolean>(false);

  const cartCheckoutDisabled = useMemo(() => {
    return (
      items.length === 0 ||
      items.some((item) => {
        // Allow if it is a product without stock
        if (!item.availability.available) {
          return false;
        }

        // Validate if quantity is more than maximum available
        return item.quantity > item.availability.maxQtyToOrder;
      })
    );
  }, [JSON.stringify(items)]);

  const handleSubmitCheckout = () => {
    const userAuth = isAthenticated();

    if (!userAuth) {
      handleOpenModalAuth({
        callbackIdentifier: !popUpsReminder ? CALLBACK_MODAL_AUTH.NAVIGATE_TO_CHECKOUT : undefined,
        initialStep: "login",
      });
      return;
    }

    if (popUpsReminder) {
      toggleAddressModal(true, CALLBACK_MODAL_ADDRESS.NAVIGATE_TO_CHECKOUT);
      onClose?.();
      return;
    }

    navigate("/checkout/confirm-cart");
  };

  const handleCheckout = () => {
    if (items.some((item) => !item.availability.available)) {
      setOpenModalItemsOutStock(true);
      return;
    }

    handleSubmitCheckout();
  };

  const handleOnSubmitItemOutStock = () => {
    handleSubmitCheckout();
    setOpenModalItemsOutStock(false);
    onAction();
  };

  useEffect(() => {
    const $scrollDiv = scrollDivRef.current;
    const $contentBottom = contentBottomRef.current;

    if ($scrollDiv && $contentBottom) {
      const checkScroll = () => {
        const { scrollHeight, clientHeight, scrollTop } = $scrollDiv;
        const hasScroll = scrollHeight > clientHeight;
        const isAtBottom = scrollHeight - scrollTop - clientHeight < 100;

        if (hasScroll && !isAtBottom) {
          $contentBottom.style.boxShadow = theme`boxShadow.elevation-top`;
        } else {
          $contentBottom.style.boxShadow = "none";
        }
      };

      checkScroll();

      $scrollDiv.addEventListener("scroll", checkScroll);
      return () => $scrollDiv.removeEventListener("scroll", checkScroll);
    }
  }, [scrollDivRef.current, contentBottomRef.current, items.length]);

  return (
    <>
      <StyledWrapper>
        <StyledWrapperBody>
          <StyledWrapperHeader>
            <Heading type="h5">Tu pedido</Heading>

            <Paragraph sizeVariant="sm" color={theme`colors.neutral.50`}>
              {totalCountCart} {totalCountCart !== 1 ? "Productos" : "Producto"}
            </Paragraph>
          </StyledWrapperHeader>

          <StyledWrapperContainerScroll ref={scrollDivRef}>
            <StyledWrapperProducts>
              {items.length > 0 ? (
                <>
                  {[...items]
                    .sort((a) => {
                      return a.availability.available ? -1 : 1;
                    })
                    .map((item) => {
                      if (item.availability.available) {
                        // Product available
                        return (
                          <ProductCardGallerySearch
                            oldDataProduct={item}
                            product={graphqlToProductGallery(item)}
                            orientation="horizontal"
                            disabled={!itemsStock?.[item.details.sku]}
                            showUnits
                            sizeCounterInput="small"
                            extraValidation={{
                              units: !itemsStock?.[item.details.sku],
                            }}
                            key={item.pk}
                          />
                        );
                      }

                      // Product doesnt available
                      return (
                        <CardDismissible
                          key={item.pk}
                          title="Este producto está agotado"
                          classes={{
                            root: tw`bg-neutral-99 p-s`,
                          }}
                          defaultOpen
                        >
                          <div tw="mt-sm">
                            <ProductCardGallerySearch
                              oldDataProduct={item}
                              product={graphqlToProductGallery(item)}
                              orientation="horizontal"
                              disabled
                              showUnits
                              sizeCounterInput="small"
                              extraValidation={{
                                units: true,
                              }}
                              key={item.pk}
                            />
                          </div>
                        </CardDismissible>
                      );
                    })}
                </>
              ) : (
                <CartEmpty />
              )}
            </StyledWrapperProducts>

            {!isMedium && (
              <StyledWrapperContent tw="border-y border-neutral-90 py-sm">
                <StyledWrapperAmounts>
                  <DetailAmount title="Subtotal" amount={formatCurrency(subTotal)} sizeVariant="md" />

                  <DetailAmount title="Descuento" amount={formatCurrency(discount)} sizeVariant="md" />
                </StyledWrapperAmounts>
              </StyledWrapperContent>
            )}
          </StyledWrapperContainerScroll>
        </StyledWrapperBody>

        {/* Responsive bottom modal */}
        <StyledWrapperContentBottom ref={contentBottomRef}>
          <StyledWrapperAmounts>
            {isMedium && (
              <>
                <DetailAmount title="Subtotal" amount={formatCurrency(subTotal)} sizeVariant="md" />

                <DetailAmount title="Descuento" amount={formatCurrency(discount)} sizeVariant="md" />
              </>
            )}

            <DetailAmount title="Total" amount={formatCurrency(total)} withIVA sizeVariant="lg" />
          </StyledWrapperAmounts>

          <Button sizeVariant="lg" fullWidth onClick={handleCheckout} disabled={cartCheckoutDisabled}>
            Realizar pedido
          </Button>

          <div tw="flex items-center justify-between gap-xs ">
            <div tw="flex flex-wrap items-center gap-m">
              <IconMastercard />
              <IconVisa />
              <IconDinersClub />
              <Image src={srcPse} tw="h-ml" />
            </div>

            <Image src={srcLyra} tw="h-ml" />
          </div>
        </StyledWrapperContentBottom>

        {/* Recommend product */}
        {/* <StyledWrapperContent tw="bg-neutral-99">
          <Heading type="h5">Te recomendamos llevar este producto</Heading>

          <StyledWrapperProducts>
            <ProductCardGallerySearch
              oldDataProduct={{}}
              product={baseProduct}
              sizeVariant="sm"
              orientation="horizontal"
            />
          </StyledWrapperProducts>
        </StyledWrapperContent> */}
      </StyledWrapper>

      {openModalItemsOutStock && (
        <ModalItemsOutOfStock
          isOpen={openModalItemsOutStock}
          handleClose={() => setOpenModalItemsOutStock(false)}
          storeId={storeId}
          onSubmit={handleOnSubmitItemOutStock}
          onlyClose
        />
      )}
    </>
  );
};

export default ShoppingCartMenu;
