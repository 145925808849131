const type = {
  v4: {
    httpsUrl: "https://api.ipify.org/",
  },
  v6: {
    httpsUrl: "https://api6.ipify.org/",
  },
};

const getIp = async (version) => {
  const data = type[version];
  try {
    const response = await fetch(data.httpsUrl as string);
    const ip = await response.text();
    return ip;
  } catch (e) {
    return null;
  }
};

export const v4 = async () => {
  return await getIp("v4");
};

export const v6 = async () => {
  return await getIp("v6");
};
