import tw, { styled } from "twin.macro";

export const styles = {
  wrapper: {
    base: tw`relative flex flex-col w-full rounded-lg medium:(w-[26rem]) large:(w-[29rem])`,
  },
  containerScroll: {
    base: tw`flex flex-col w-full overflow-y-auto h-[calc(100dvh - 17rem)] medium:(h-full max-h-[calc(50dvh - 36px)])`,
  },
  content: {
    base: tw`flex flex-col w-full p-sm gap-m bg-white transition-all duration-300 ease-in-out`,
  },
  contentBottom: {
    base: tw`fixed bottom-0 border-y border-neutral-90 medium:(static rounded-b-lg)`,
  },
  header: {
    base: tw`flex items-center justify-between p-sm pb-0 rounded-t-lg`,
  },
  body: {
    base: tw`flex flex-col gap-m bg-white rounded-t-lg`,
  },
  products: {
    base: tw`flex flex-col gap-s xxs:(px-sm) mb-sm all-child:(w-full)`,
  },
  amounts: {
    base: tw`flex flex-col gap-xs w-full`,
  },
  noFound: {
    base: tw`flex items-center justify-center flex-1 h-full p-m medium:(p-ml)`,
  },
};

export const StyledWrapper = styled.div`
  ${styles.wrapper.base}
`;

export const StyledWrapperContainerScroll = styled.div`
  ${styles.containerScroll.base}
`;
export const StyledWrapperContent = styled.div`
  ${styles.content.base}
`;
export const StyledWrapperContentBottom = styled.div`
  ${styles.content.base}
  ${styles.contentBottom.base}
`;

export const StyledWrapperHeader = styled.div`
  ${styles.header.base}
`;
export const StyledWrapperBody = styled.div`
  ${styles.body.base}
`;
export const StyledWrapperProducts = styled.div`
  ${styles.products.base}
`;
export const StyledWrapperAmounts = styled.div`
  ${styles.amounts.base}
`;
