import type { TSize } from "@/types/common";
import applyResponsiveStyles from "@/utils/applyResponsiveStyles";
import tw, { css, styled } from "twin.macro";

const styles = {
  btnWrapper: {
    base: tw`
    border border-neutral-40 bg-neutral-100 transition-all duration-300 ease-in-out
    [& > svg]:(w-[0.625rem] h-[0.625rem] transition-all duration-300 ease-in-out)
    [& > svg path]:(fill-neutral-40)
  `,
    size: {
      small: tw`rounded-s p-xs [& > svg]:(w-[1.125rem] h-[1.125rem])`,
      medium: tw`rounded-m p-xs [& > svg]:(w-[1.25rem] h-[1.25rem])`,
      large: tw`rounded-l p-xs [& > svg]:(w-[1.25rem] h-[1.25rem])`,
    },
  },

  btnIncrement: tw`rounded-l-none`,

  btnDecrement: tw`rounded-r-none`,

  btnRemove: {
    base: tw`
    [& > svg path]:(fill-neutral-40)
    hover:[& > svg path]:(fill-neutral-10)
  `,
    size: {
      base: {
        small: tw`p-xs`,
        medium: tw`p-xs`,
        large: tw`p-xs`,
      },
      svg: {
        small: tw`w-[1.125rem] h-[1.125rem]`,
        medium: tw`w-[1.25rem] h-[1.25rem]`,
        large: tw`w-[1.25rem] h-[1.25rem]`,
      },
    },
  },

  wrapperInput: tw`
    flex items-center w-full gap-xs
    [& > div]:(flex w-full)
  `,

  input: {
    base: tw`w-full text-center border-t border-b bg-neutral-100 rounded-none text-neutral-40 px-xs transition-all duration-300 ease-in-out`,
    size: {
      small: tw`text-[0.875rem] tracking-[0.00875rem]`,
      medium: tw`text-base leading-[0.01rem] tracking-normal`,
      large: tw`text-lg leading-none tracking-normal`,
    },
  },

  wrapperCounterInput: (hasValue?: boolean) => [
    tw`flex flex-col gap-xs`,
    css`
      &:hover {
        & ${BtnWrapper} {
          ${tw`border-neutral-10`}
          & > svg {
            & path {
              ${tw`fill-neutral-10`}
            }
          }
        }
      }
    `,
    hasValue &&
      css`
        & ${StyledInput} {
          ${tw`border-neutral-10 text-neutral-0`}
        }
        & ${BtnWrapper} {
          ${tw`border-neutral-10`}
          & > svg {
            & path {
              ${tw`fill-neutral-10`}
            }
          }
        }
      `,
  ],
};

const BtnWrapper = styled.button<{ $size?: TSize }>`
  ${styles.btnWrapper.base}

  ${({ $size }) => {
    return applyResponsiveStyles({ stylesBreakpoints: styles.btnWrapper.size, size: $size });
  }}

  ${({ disabled }) => disabled && tw`bg-neutral-90 cursor-not-allowed`}
`;

export const StyledWrapperBtnIncrement = styled(BtnWrapper)(styles.btnIncrement);

export const StyledWrapperBtnDecrement = styled(BtnWrapper)(styles.btnDecrement);

export const StyledWrapperBtnRemove = styled.button<{ $size?: TSize }>`
  ${styles.btnRemove.base}

  ${({ $size }) => {
    return applyResponsiveStyles({ stylesBreakpoints: styles.btnRemove.size.base, size: $size });
  }}

  & > svg {
    ${({ $size }) => {
      return applyResponsiveStyles({ stylesBreakpoints: styles.btnRemove.size.svg, size: $size });
    }}
  }
`;

export const StyledWrapperInput = styled.div(styles.wrapperInput);

export const StyledInput = styled.input<{ $error: boolean; $size?: TSize }>`
  ${styles.input.base}

  ${({ $error }) => ($error ? tw`border-error-40! text-error-40! bg-error-99` : tw`border-neutral-80`)}

  &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }

  ${({ $size }) => {
    return applyResponsiveStyles({ stylesBreakpoints: styles.input.size, size: $size });
  }}
`;

export const StyledWrapperCounterInput = styled.div<{ $hasValue?: boolean }>(({ $hasValue }) =>
  styles.wrapperCounterInput($hasValue)
);
