import tw, { styled } from "twin.macro";
import { Link } from "react-router-dom";
import type { AddDollarToKeys, TOrientation, TSize, TSizeVariant } from "@/types/common";
import applyResponsiveStyles from "@/utils/applyResponsiveStyles";

interface IProductCardGalleryStyled {
  sizeVariant: TSizeVariant;
  size?: TSize;
  orientation?: TOrientation;
  showInputCounter?: boolean;
  isPromoted?: boolean;
  active?: boolean;
}

// MD is equal to LG
const styles = {
  card: {
    sm: {
      vertical: {
        small: tw`w-[6.875rem] min-h-[9.875rem] rounded-lg p-2 gap-1 `,
        medium: tw`w-[6.875rem] min-h-[12.5rem] rounded-md p-2 gap-1 `,
        large: tw`w-[9.625rem] min-h-[12.625rem] rounded-md p-2 gap-1`,
      },
      horizontal: {
        small: tw`w-[10.125rem] min-h-[8rem] rounded-lg p-2 gap-2 `,
        medium: tw`w-[11.125rem] min-h-[9rem] rounded-md p-2 gap-2 `,
        large: tw`w-[11.125rem] min-h-[10rem] rounded-md p-2 gap-2 `,
      },
    },
    md: {
      vertical: {
        small: tw`w-[8.75rem] min-h-[18.75rem] rounded-lg gap-2`,
        medium: tw`w-[10.625rem] min-h-[21.5rem] rounded-md gap-2 `,
        large: tw`w-[12.5rem] min-h-[26.875rem] rounded-md gap-4 `,
      },
      horizontal: {
        small: tw`max-[320px]:min-w-full min-w-[18.75rem]  rounded-lg gap-2 `,
        medium: tw`min-w-[24rem] h-[14rem] rounded-md gap-2 `,
        large: tw`min-w-[24rem] h-[15rem] rounded-md gap-2`,
      },
    },
    lg: {
      vertical: {
        small: tw`w-[8.75rem] min-h-[18.75rem] rounded-lg gap-2`,
        medium: tw`min-w-[10.625rem] max-w-[12.5rem]  min-h-[21.5rem] rounded-md gap-2`,
        large: tw`w-[12.5rem] min-h-[26.875rem] rounded-md gap-4`,
      },
      horizontal: {
        small: tw`min-w-full h-[10rem] rounded-lg gap-2 `,
        medium: tw`min-w-full h-[11.5rem] rounded-md gap-2`,
        large: tw`min-w-full h-[11.5rem] rounded-md gap-2`,
      },
    },
  },
  figure: {
    sm: {
      vertical: {
        small: tw`w-[2.5rem] h-[2.5rem] rounded-s`,
        medium: tw`w-[2.5rem] h-[2.5rem] rounded-s`,
        large: tw`w-[3.5rem] h-[3.5rem] rounded-s`,
      },
      horizontal: {
        small: tw`w-[3.5rem] h-[3.5rem] rounded-s `,
        medium: tw`w-[4.5rem] h-[4.5rem] rounded-s`,
        large: tw`w-[5.5rem] h-[5.5rem] rounded-s`,
      },
    },
    md: {
      vertical: {
        small: tw`w-[8.75rem] h-[8.75rem] rounded-t-lg`,
        medium: tw`w-[10.625rem] h-[10.625rem] rounded-t-md`,
        large: tw`w-[12.5rem] h-[12.5rem] rounded-t-md`,
      },
      horizontal: {
        small: tw`max-[320px]:w-[5rem] w-[8.75rem] h-[10rem] rounded-l-lg`,
        medium: tw`w-[10.625rem] h-full rounded-l-md `,
        large: tw`w-[12.5rem] h-full rounded-l-md`,
      },
    },
    lg: {
      vertical: {
        small: tw`w-[8.75rem] h-[8.75rem] rounded-t-lg`,
        medium: tw`w-[10.625rem] h-[10.625rem] rounded-t-md`,
        large: tw`w-[12.5rem] h-[12.5rem] rounded-t-md`,
      },
      horizontal: {
        small: tw`w-[8.75rem] h-[10rem] rounded-l-lg`,
        medium: tw`w-[10.625rem] h-[11.875rem] rounded-l-md`,
        large: tw`w-[12.5rem] h-[12.5rem] rounded-l-md`,
      },
    },
  },
  content: {
    sm: {
      vertical: { small: tw`gap-xxs`, medium: tw`gap-xxs`, large: tw`gap-xxs` },
      horizontal: {
        small: tw`gap-xxs flex-row items-center`,
        medium: tw`gap-xxs flex-row`,
        large: tw`gap-xxs flex-row`,
      },
    },
    md: {
      vertical: { small: tw`p-2 gap-xs`, medium: tw`p-2 gap-xs`, large: tw`p-2 gap-xs` },
      horizontal: { small: tw`p-2 gap-xs`, medium: tw`p-2 gap-sm`, large: tw`p-2 gap-sm` },
    },
    lg: {
      vertical: { small: tw`p-2 gap-s`, medium: tw`p-2 gap-s`, large: tw`p-2 gap-s` },
      horizontal: { small: tw`p-2 gap-s`, medium: tw`p-2 gap-s`, large: tw`p-2 gap-s` },
    },
  },
};

export const StyledWrapperHelpMessage = styled.div`
  ${tw`flex items-center w-full gap-xxs`}
`;

export const StyledWrapperFigure = styled.figure<{ $disabled: boolean }>`
  ${tw`relative overflow-hidden shrink-0`}
  & span {
    ${tw`h-full `}
  }
  & img {
    ${tw`object-cover`}

    ${({ $disabled }) => {
      if ($disabled) {
        return tw`!opacity-40`;
      }
    }};
  }

  & .wrapper-promoted {
    ${tw`absolute bottom-sm right-xs h-max`}
  }

  & .wrapper-custom-label {
    ${tw`absolute top-xxs z-10`}
  }
`;

export const StyledWrapperContent = styled.div`
  ${tw`flex flex-col grow-[1]`}
`;

export const StyledWrapperContentText = styled.div`
  ${tw`flex flex-col gap-xxs w-full`}
`;

export const StyledWrapperContentPrice = styled.div`
  ${tw`flex items-center justify-between w-full`}
`;

export const StyledWrapperContentDiscount = styled.div`
  ${tw`flex gap-s`}
`;

export const StyledWrapperTagNewDiscount = styled.div``;

export const StyledWrapperButton = styled.div`
  ${tw`flex flex-col items-stretch justify-end gap-xs grow-[1]`}
`;

export const StyleButton = styled.div`
  ${tw`flex items-center justify-between`}

  & .counter-input {
    ${tw`max-w-[15rem]`}
  }
`;

export const StyledWrapperTagCustom = styled.div``;

export const StyledWrapperCard = styled(Link)<AddDollarToKeys<IProductCardGalleryStyled>>`
  ${tw`flex border shrink-0 overflow-hidden`}
  ${({ $orientation, $sizeVariant }) =>
    $orientation === "vertical" || $sizeVariant === "sm" ? tw`flex-col` : tw`flex-row`}

  ${({ $size, $orientation, $sizeVariant }) =>
    applyResponsiveStyles({
      stylesBreakpoints: styles.card[$sizeVariant][$orientation === "vertical" ? "vertical" : "horizontal"],
      size: $size,
    })}

  ${({ $isPromoted, $active }) =>
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    $isPromoted || $active ? tw`border-primaryV2-70 shadow-orange` : tw`border-neutral-90`}

  & ${StyledWrapperFigure} {
    ${({ $size, $orientation, $sizeVariant }) =>
      applyResponsiveStyles({
        stylesBreakpoints:
          styles.figure[$sizeVariant][$orientation === "vertical" ? "vertical" : "horizontal"],
        size: $size,
      })}

    & img {
      ${tw`object-cover h-full w-full`}
    }
  }

  & ${StyledWrapperContent} {
    ${({ $size, $orientation, $sizeVariant }) =>
      applyResponsiveStyles({
        stylesBreakpoints:
          styles.content[$sizeVariant][$orientation === "vertical" ? "vertical" : "horizontal"],
        size: $size,
      })}
  }

  & ${StyleButton} > :first-child {
    ${({ $orientation, $sizeVariant }) =>
      $orientation !== "vertical" && $sizeVariant === "lg" ? tw`basis-[14rem]` : tw`basis-[100%]`}
  }

  & ${StyledWrapperHelpMessage} {
    ${({ $sizeVariant, $orientation }) =>
      $orientation !== "vertical" && $sizeVariant === "lg" ? tw` justify-start` : tw` justify-center`}
  }

  & ${StyledWrapperContentDiscount} {
    ${({ $orientation, $sizeVariant }) =>
      $orientation === "vertical" && $sizeVariant === "sm" && tw`justify-between`}
  }
`;
