import { type ReactNode, useContext } from "react";
import { Navigate } from "react-router-dom";

import { sessionContext } from "@/context";

export const PrivateRoute = ({ children }: { children: ReactNode }) => {
  const { userToken } = useContext(sessionContext);
  const isLoggedIn = !(userToken === null);

  if (!isLoggedIn) {
    return <Navigate to="/stores/market" replace />;
  }

  return <>{children}</>;
};
