import tw, { css, styled, type TwStyle } from "twin.macro";

import type { TSize, TColor } from "@/types/common";
import { type RuleSet } from "styled-components";

const radioButtonStyles = {
  colors: {
    primary: {
      background40: tw`bg-primaryV2-40`,
      background90: tw`bg-primaryV2-90`,
      border40: tw`border-primaryV2-40`,
    },
    secondary: {
      background40: tw`bg-secondary-40`,
      background90: tw`bg-secondary-90`,
      border40: tw`border-secondary-40`,
    },
  },
};

export const StylesRoundedInput = styled.span<{ $size?: TSize }>`
  ${tw`rounded-full w-[0.75rem] h-[0.75rem] border border-neutral-40 transition-all duration-300 ease-in-out overflow-hidden flex items-center justify-center
    medium:(w-[1rem] h-[1rem])
    large:(w-[1.5rem] h-[1.5rem])
  `};
  & > span {
    ${tw`flex w-full h-full rounded-full`}
  }
  ${({ $size }) => {
    if ($size) {
      switch ($size) {
        case "medium":
          return tw`!(w-[1rem] h-[1rem])`;
        case "large":
          return tw`!(w-[1.5rem] h-[1.5rem])`;
        default:
          return tw`!(w-[0.75rem] h-[0.75rem])`;
      }
    }
  }}
`;

export const StyledWrapperRadioButton = styled.div<{ $size?: TSize }>`
  ${tw`flex items-center justify-center w-[1.0625rem] h-[1.0625rem] rounded-full transition-all duration-300 ease-in-out
    medium:(w-[1.41669rem] h-[1.41669rem])
    large:(w-[2.125rem] h-[2.125rem])
  `}
  ${({ $size }) => {
    if ($size) {
      switch ($size) {
        case "medium":
          return tw`!(w-[1.41669rem] h-[1.41669rem])`;
        case "large":
          return tw`!(w-[2.125rem] h-[2.125rem])`;
        default:
          return tw`!(w-[1.0625rem] h-[1.0625rem])`;
      }
    }
  }}
`;

export const StyledWrapperRoot = styled.div<{
  $disabled?: boolean;
  $hasLabel?: boolean;
  $addDefaultPadding?: boolean;
  $color: TColor;
  $classes?: RuleSet<object> | TwStyle;
}>`
  ${tw`relative flex gap-2 items-center w-max`}
  ${({ $classes }) => $classes && $classes}
  ${({ $addDefaultPadding, $hasLabel }) => {
    if ($hasLabel && $addDefaultPadding) {
      return tw`py-2 px-4 medium:(p-4)`;
    }
  }}
  & > input {
    ${tw`w-full h-full opacity-0 absolute cursor-pointer`}
  }
  & > input:checked + ${StyledWrapperRadioButton} > ${StylesRoundedInput} {
    ${({ $color }) => {
      return radioButtonStyles.colors[$color].border40;
    }};

    & > span {
      ${tw`w-[70%] h-[70%]`}

      ${({ $color }) => {
        return radioButtonStyles.colors[$color].background40;
      }};
    }
  }
  &:hover,
  &:focus {
    ${({ $hasLabel }) => $hasLabel && tw`bg-neutral-99`}
    & ${StyledWrapperRadioButton} {
      ${tw`bg-neutral-90`}
      & > ${StylesRoundedInput} {
        ${tw`border-neutral-30`}
      }
    }
  }
  &:active {
    ${StyledWrapperRadioButton} {
      ${({ $color }) => {
        return radioButtonStyles.colors[$color].background90;
      }};

      & > ${StylesRoundedInput} {
        ${({ $color }) => {
          return radioButtonStyles.colors[$color].border40;
        }};
      }
    }
  }
  ${({ $disabled }) => {
    if ($disabled) {
      return css`
        ${tw`cursor-not-allowed pointer-events-none`};
        & > label {
          ${tw`text-neutral-70`}
        }
        & ${StylesRoundedInput} {
          ${tw`border-neutral-30!`}
          & > span {
            ${tw`!(w-full h-full bg-neutral-90 flex)`}
          }
        }
      `;
    }
  }}
`;
