import { COUNTRYCODE } from "@/recourses/constants";
import {
  StoreFitHubBarranquilla,
  StoreFitHubBogota,
  StoreFitHubBarranquillaMin,
  StoreFitHubBogotaMin,
} from "../../assets";

type Countries = CountryType[];

export const getCities = (code = COUNTRYCODE) => {
  return countries.find((country) => country.code === code)?.cities;
};

export const filterCities = (code: CountryCode, available: boolean) => {
  const country = countries.find((c) => c.code === code);

  if (!country) {
    return [];
  }

  return country.cities.filter((city) => city.is_available === available);
};

export const countries: Countries = [
  {
    _id: "d7bfa9f93b5f7bb51e060bea290023b7",
    _rev: "6-c37eb62c0f38279da52092e8279294e0",
    name: "Colombia",
    currency: "COP",
    code: "CO",
    image: "https://fithub-media.s3.amazonaws.com/colombia.png",
    is_available: true,
    cities: [
      {
        name: "Barranquilla",
        code: "barranquilla",
        lat: "11.008687",
        lng: "-74.810105",
        image: StoreFitHubBarranquilla,
        lowImageQuality: StoreFitHubBarranquillaMin,
        is_available: true,
        is_new: false,
        is_National: false,
        city: "barranquilla",
      },
      {
        name: "Bogotá D.C",
        code: "bogota",
        lat: "4.675344",
        lng: "-74.049563",
        image: StoreFitHubBogota,
        lowImageQuality: StoreFitHubBogotaMin,
        is_available: true,
        is_new: false,
        is_National: false,
        city: "bogota",
      },
      {
        name: "Medellín",
        code: "medellin",
        lat: "6.210319",
        lng: "-75.597305",
        is_available: false,
        is_new: true,
        is_National: false,
        city: "medellin",
      },
      {
        name: "Cartagena",
        code: "cartagena",
        lat: "10.390235",
        lng: "-75.486382",
        is_available: false,
        is_new: false,
        is_National: true,
        city: "",
      },
      {
        name: "Cali",
        code: "cali",
        lat: "3.445139",
        lng: "-76.518808",
        is_available: false,
        is_new: false,
        is_National: true,
        city: "",
      },
      {
        name: "Pereira",
        code: "pereira",
        lat: "4.805875",
        lng: "-75.694524",
        is_available: false,
        is_new: false,
        is_National: true,
        city: "",
      },
      {
        name: "Sta. Marta",
        code: "sta_marta",
        lat: "11.238729",
        lng: "-74.201524",
        is_available: false,
        is_new: false,
        is_National: true,
        city: "",
      },
      {
        name: "Montería",
        code: "monteria",
        lat: "8.753261",
        lng: "-75.880183",
        is_available: false,
        is_new: false,
        is_National: true,
        city: "",
      },
      {
        name: "Sincelejo",
        code: "sincelejo",
        lat: "9.300577",
        lng: "-75.392169",
        is_available: false,
        is_new: false,
        is_National: true,
        city: "",
      },
      {
        name: "Riohacha",
        code: "riohacha",
        lat: "11.539739",
        lng: "-72.914313",
        is_available: false,
        is_new: false,
        is_National: true,
        city: "",
      },
      {
        name: "Valledupar",
        code: "valledupar",
        lat: "10.471022",
        lng: "-73.254034",
        is_available: false,
        is_new: false,
        is_National: true,
        city: "",
      },
      {
        name: "Bucaramanga",
        code: "bucaramanga",
        lat: "7.103580",
        lng: "-73.124402",
        is_available: false,
        is_new: false,
        is_National: true,
        city: "",
      },
      {
        name: "Villavicencio",
        code: "villavicencio",
        lat: "4.132800",
        lng: "-73.623689",
        is_available: false,
        is_new: false,
        is_National: true,
        city: "",
      },
      {
        name: "Manizales",
        code: "manizales",
        lat: "5.052097",
        lng: "-75.483134",
        is_available: false,
        is_new: false,
        is_National: true,
        city: "",
      },
      {
        name: "Armenia",
        code: "armenia",
        lat: "4.536147",
        lng: "-75.677780",
        is_available: false,
        is_new: false,
        is_National: true,
        city: "",
      },
      {
        name: "Tunja",
        code: "tunja",
        lat: "5.535077",
        lng: "-73.356704",
        is_available: false,
        is_new: false,
        is_National: true,
        city: "",
      },
      {
        name: "Yopal",
        code: "yopal",
        lat: "5.335320",
        lng: "-72.394528",
        is_available: false,
        is_new: false,
        is_National: true,
        city: "",
      },
      {
        name: "Neiva",
        code: "neiva",
        lat: "2.928591",
        lng: "-75.289021",
        is_available: false,
        is_new: false,
        is_National: true,
        city: "",
      },
      {
        name: "Ibagué",
        code: "ibague",
        lat: "4.435027",
        lng: "-75.211564",
        is_available: false,
        is_new: false,
        is_National: true,
        city: "",
      },
      {
        name: "Cucuta",
        code: "cucuta",
        lat: "7.885191",
        lng: "-72.491974",
        is_available: false,
        is_new: false,
        is_National: true,
        city: "",
      },
      {
        name: "Pasto",
        code: "pasto",
        lat: "1.206750",
        lng: "-77.269681",
        is_available: false,
        is_new: false,
        is_National: true,
        city: "",
      },
      {
        name: "Florencia",
        code: "florencia",
        lat: "1.617678",
        lng: "-75.609056",
        is_available: false,
        is_new: false,
        is_National: true,
        city: "",
      },
      {
        name: "Popayán",
        code: "popayan",
        lat: "2.449661",
        lng: "-76.608511",
        is_available: false,
        is_new: false,
        is_National: true,
        city: "",
      },
    ],
    stages: {
      staging: {
        base_url: "https://api-staging.fithub.com.co/",
        api_url: "https://api-staging.fithub.com.co/api/v1/",
      },
      production: {
        base_url: "https://api.fithub.com.co/",
        api_url: "https://api.fithub.com.co/api/v1/",
      },
    },
  },
  {
    _id: "d7bfa9f93b5f7bb51e060bea29005e73",
    _rev: "5-53f7861cb85ffa43893db8a5bb9cb945",
    name: "México",
    currency: "MXN",
    code: "MX",
    image: "https://fithub-media.s3.amazonaws.com/mexico.png",
    is_available: false,
    cities: [
      {
        name: "Ciudad de México",
        code: "ciudad_de_mexico",
        lat: "19.418679",
        lng: "-99.165373",
        is_available: false,
        is_new: true,
        is_National: true,
        city: "",
      },
    ],
    stages: {
      staging: {
        base_url: "https://api-staging.fithub.com.mx/",
        api_url: "https://api-staging.fithub.com.mx/api/v1/",
      },
      production: {
        base_url: "https://api.fithub.com.mx/",
        api_url: "https://api.fithub.com.mx/api/v1/",
      },
    },
  },
];
